/* Admin part */
.AdminFreeTemplates h3 {
    margin: 0;
}

.AdminFreeTemplates .template-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdminFreeTemplates.Modal .modal-body {
    min-height: auto;
}

.AdminFreeTemplates.Modal .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 42px;
    border: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.AdminFreeTemplates.Modal .upload:hover {
    background-color: #fafafa;
}

.AdminFreeTemplates.Modal .rotate {
    animation: spin 1s linear infinite;
}

.AdminFreeTemplates.Modal .imageSelection {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
}

.AdminFreeTemplates.Modal .imageSelection.sportrait {
    height: 480px;
    width: 270px;
    user-select: none;
}

.AdminFreeTemplates.Modal .imageSelection.slandscape {
    width: 480px;
    height: 270px;
    user-select: none;
}

.AdminFreeTemplates.Modal .imageSelection img {
    transform-origin: top center;
    height: max-content;
    outline: 1px solid #333;
}

.AdminFreeTemplates.Modal .imageSelection .zone {
    border: 1px solid rgb(15, 31, 255);
    background-color: rgba(61, 160, 253, 0.5);
    position: absolute;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
}

.AdminFreeTemplates.Modal .imageSelection .zone::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.3);
    left: calc(50% - 0.5px);
}

.AdminFreeTemplates.Modal .imageSelection .zone::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    top: calc(50% - 0.5px);
}

.AdminFreeTemplates.Modal .imageSelection .zone .resize {
    cursor: nwse-resize;
    display: flex;
    position: absolute;
    bottom: -7px;
    right: -7px;
    height: 14px;
    width: 14px;
    background-color: #333;
    border-radius: 7px;
}

.fixedTemplateContainer .zone {
    position: absolute;
    transform-origin: top left;
}

.AdminFreeTemplates.Modal .colorPalette {
    display: flex;
}

.AdminFreeTemplates.Modal .colorPalette .color {
    height: 24px;
    width: 24px;
    border: 1px solid #ddd;
    margin: 0 5px 5px 0;
    border-radius: 3px;
    position: relative;
}

.AdminFreeTemplates.Modal .colorPalette .color:hover .remove {
    opacity: 1;
}

.AdminFreeTemplates.Modal .colorPalette .color .remove {
    position: absolute;
    background-color: #666;
    border-radius: 7px;
    height: 14px;
    width: 14px;
    color: white;
    font-size: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    top: -7px;
    right: -7px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.AdminFreeTemplates.Modal .colorPalette .color.add {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border: 1px dashed #ddd;
    padding: 0;
    background-color: #fff;
    appearance: none;
}

.AdminFreeTemplates.Modal .colorPalette input.color.add {
    border: none;
    margin: 0;
}

.AdminFreeTemplates.Modal .colorPalette input[type='color' i]:focus {
    outline: none;
}

.AdminFreeTemplates.Modal .colorPalette input[type='color' i] {
    display: none;
    appearance: none;
}

.Slide.adm .PreviewTemplate.slandscape {
    transform: scale(0.5);
    transform-origin: top left;
    height: 90px;
}

/* List */
.templateList {
    display: flex;
    flex-wrap: wrap;
}

.templateList .templateItem,
.templateList .templateItemFree {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    flex-direction: column;
    user-select: none;
    position: relative;
    margin-bottom: 10px;
}

.templateList .templateItem p.nomargin,
.templateList .templateItemFree p.nomargin {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal .templateList .templateItem {
    width: 230px;
}

.templateList .templateItem .fixedTemplateContainer,
.templateList .templateItemFree .fixedTemplateContainer {
    position: relative;
    transition: filter 0.2s ease-in-out;
}

.templateList .templateItem img:hover,
.templateList .templateItemFree img:hover {
    filter: brightness(1.2);
}

.templateList .templateItem .fixedTemplateContainer img:hover {
    filter: none;
}

.templateList .templateItem .fixedTemplateContainer:hover {
    filter: brightness(1.2);
}

.templateList .templateItem .fixedTemplateContainer p {
    margin: 0;
}

.templateList .templateItem img,
.templateList .templateItemFree img {
    width: 100%;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}

.templateList .templateItemFree img {
    width: auto;
    max-width: 100%;
    max-height: 160px;
}

.templateList .templateItem .zone {
    cursor: pointer;
}

.templateList .templateItem.selected .fixedTemplateContainer::after,
.templateList .templateItemFree.selected::after {
    background-color: rgba(0, 0, 0, 0.5);
    color: #ddd;
    font-size: 18px;
    content: '\2713';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
}

.templateList .templateItemFree.selected::after {
    height: 160px;
    width: 90px;
}

.templateList .templateItemFree.landscape.selected::after,
.templateList .templateItemFree.led_448_340.selected::after {
    width: 160px;
    height: 90px;
}

.templateList .templateItemFree.led_448_340.selected::after {
    height: 121px;
}

/* Preview */
.PreviewTemplate {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
}

.PreviewTemplate.sportrait {
    height: 480px;
    width: 270px;
    user-select: none;
    position: relative;
}

.PreviewTemplate.slandscape {
    width: 320px;
    height: 180px;
    user-select: none;
    position: relative;
}

.PreviewTemplate.sled_448_340 {
    width: 224px;
    height: 170px;
    user-select: none;
    position: relative;
}

.Assistant .PreviewTemplate.slandscape {
    width: 480px;
    height: 270px;
    user-select: none;
    position: relative;
    margin-top: 24px;
}

.Assistant .PreviewTemplate.sled_448_340 {
    width: 448px;
    height: 340px;
    user-select: none;
    position: relative;
    margin-top: 24px;
}

.PreviewTemplate img {
    transform-origin: top center;
    height: max-content;
    outline: 1px solid #333;
}

.PreviewTemplate .zone {
    position: absolute;
    transform-origin: top left;
}

.PreviewTemplate .zone p {
    line-height: 1;
    margin: 0;
}

.Slide .PreviewTemplate.sportrait {
    height: 320px;
    width: 180px;
}

@media (max-width: 1024px) {
    .Slide .PreviewTemplate.sportrait,
    .PreviewTemplate.sportrait {
        height: 160px;
        width: 90px;
    }

    .Slide .PreviewTemplate.slandscape,
    .Assistant .PreviewTemplate.slandscape {
        width: 160px;
        height: 90px;
    }

    .templateList .templateItem.selected::after {
        height: 105px;
        width: 60px;
    }
}
