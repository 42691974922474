/* .youtubetuto {
    display: flex;
} */

.allyoutubevid {
    display: flex;
    justify-content: space-between;
}

.myvid {
    margin: 20px;
}

.fa-shoe-prints {
    margin-right: 10px;
}

.mytuto {
    text-align: center;
}

.youtubetuto h2 {
    text-align: center;
}

.hr_separate{ 
    margin-top: 5%;
    border: none;
    border-left: 1px solid hsla(200, 10%, 50%,100);
    height: auto;
    width: 1px;
}

.myvid iframe {
    width: 560px;
    height: 315px;
}


.paneltuto {
    margin-left: -20px;
}

@media (max-width: 1550px) {
    .allyoutubevid {
        display: block;
    }

    .youtubetuto h2 {
        text-align: initial !important;
    }

    .mytuto {
        text-align: initial !important;
        margin-left: 20%;
    }
}

@media (max-width: 720px) {
    .myvid iframe {
        width: auto;
        height: 315px;
    }
}