@font-face {
    font-family: 'SanaSansAltBlack';
    src: url('/fonts/Sana Sans Alt W00 Black.ttf') format('truetype');
}

@font-face {
    font-family: 'SanaSansAltBook';
    src: url('/fonts/Sana Sans Alt W00 Book.ttf') format('truetype');
}

.MyAuchan {
    text-align: center;
    font-family: 'SanaSansAltBlack';
}

.MyAuchan span,
.MyAuchan p,
.MyAuchan h1,
.MyAuchan h2,
.MyAuchan h3,
.MyAuchan h4 {
    font-family: inherit;
}

.MyAuchan.portrait,
.MyAuchan.portrait_direct {
    height: 1920px;
    width: 1080px;

    background-size: 100% 100%;
    position: relative;
}

.Slide .PreviewFruitContainer .MyAuchan.portrait,
.Slide .PreviewFruitContainer .MyAuchan.portrait_direct {
    height: 1870px;
    width: 1030px;
}

.canvas .MyAuchan.portrait,
.canvas .MyAuchan.portrait_direct {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
}

.MyAuchan .origin {
    display: flex;
    display: flex;
    left: 640px;
    position: absolute;
    flex-direction: column;
    top: 101px;
}

.MyAuchan .origin .origin-ctr {
    display: flex;
    align-items: center;
}

.MyAuchan .origin .origin-ctr .countryFlag {
    max-height: 25px;
}

.MyAuchan .origin .origin-ctr span {
    font-family: 'Branding SF Narrow Medium';
    font-size: 44.5px;
    letter-spacing: -0.45px;
    color: #2a3336;
    margin-right: 10px;
}

.MyAuchan .origin h5 {
    font-size: 116px;
    font-family: 'Branding SF Narrow Medium';
    font-weight: 300;
    letter-spacing: -1.17px;
    margin: 0;
    line-height: 0.8;
    text-transform: uppercase;
    text-align: left;
    position: fixed;
    top: 160px;
    word-break: break-all;
    max-width: 580px;
    z-index: 9;
    transform: scaleX(0.8);
    transform-origin: top left;
}

.canvas .MyAuchan .origin h5 {
    top: 185px;
}

.MyAuchan .image {
    position: absolute;
    left: 0;
    top: 322px;
    width: 808px;
}

.MyAuchan.caterer .image {
    width: 650px;
    top: 400px;
}

.MyAuchan .image img {
    max-width: 100%;
}

.MyAuchan .data-price {
    position: absolute;
    right: 96px;
    top: 881px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MyAuchan .data-price .price-ctr {
    max-width: 230px;
    font-size: 38px;
    color: #2a3336;
    margin-bottom: 24px;
    text-transform: capitalize;
}

.MyAuchan .data-price .price {
    display: flex;
    color: #2a3336;
}

.MyAuchan .data-price .price .num {
    font-size: 205px;
    font-weight: 900;
    letter-spacing: -5.13px;
    line-height: 170px;
}

.MyAuchan .data-price .price .sec-part {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    align-items: flex-start;
}

.MyAuchan .data-price .price .sec-part .eur {
    font-size: 78.5px;
    line-height: 63px;
    align-self: flex-start;
    margin-top: 6px;
}

.MyAuchan .data-price .price .sec-part .cts {
    margin-top: 6px;
    font-size: 80px;
    line-height: 105px;
    font-weight: 300;
}

.MyAuchan .data-price .sep {
    height: 8px;
    width: 100%;
    background-color: #f80000;
    margin-bottom: 8px;
}

.MyAuchan .data-price .kilo-price {
    font-size: 33px;
    font-weight: 900;
    color: #f80000;
    text-transform: uppercase;
}

.MyAuchan .data-price .price-min {
    display: flex;
    color: #f80000;
    margin-top: 14px;
}

.MyAuchan .data-price .price-min .num {
    font-size: 113px;
    font-weight: 900;
    letter-spacing: -2.83px;
    line-height: 74px;
}

.MyAuchan .data-price .price-min .sec-part {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
}

.MyAuchan .data-price .price-min .sec-part .eur {
    font-size: 43px;
    line-height: 29px;
    align-self: flex-start;
}

.MyAuchan .data-price .price-min .sec-part .cts {
    margin-top: 6px;
    font-size: 44px;
    line-height: 53px;
}

.MyAuchan .data {
    position: absolute;
    bottom: 200px;
    padding-left: 108px;
    color: #2a3336;
    text-align: left;
    font-family: 'SanaSansAltBook';
    display: flex;
    flex-direction: column;
}

.MyAuchan .data h1 {
    font-size: 120px;
    line-height: 108px;
    letter-spacing: -1.21px;
    font-family: 'SanaSansAltBlack';
    margin: 20px 0;
    max-width: 550px;
}

.MyAuchan .data p {
    line-height: 48px;
    font-size: 40px;
}

.MyAuchan .promo {
    position: absolute;
    top: 81px;
    left: 69px;
    text-align: left;
}

.MyAuchan .promo p {
    font-size: 32px;
    font-weight: 900;
    color: #f80000;
    text-transform: uppercase;
    text-align: center;
}

.MyAuchan .promo img {
    width: 495px;
}

.MyAuchan .bg {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

.canvas .MyAuchan .bg {
    width: calc(100% - 50px);
    position: fixed;
    bottom: 25px;
    left: 25px;
}

.MyAuchan .labels {
    display: block;
    margin: 0 -5px;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
}

.MyAuchan .labels img {
    max-height: 105px;
    min-height: 105px;
    margin: 0px 5px;
}

.MyAuchan .data .flex-bakery {
    display: flex;
    gap: 25px;
}

.MyAuchan .data .flex-bakery>.card {
    padding: 0;
    box-shadow: none;
    margin: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
}

.MyAuchan .data .flex-bakery>.card p {
    font-size: 24px;
}

.MyAuchan .data .flex-bakery img {
    max-width: 40px;
}
