@font-face {
    font-family: 'museo-slab-300';
    src: url('/fonts/MuseoSlab-300_0.otf') format('opentype');
}

@font-face {
    font-family: 'blog-script-u';
    src: url('/fonts/BlogScriptpourU-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'bevan';
    src: url('/fonts/Bevan-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'cabinsketch';
    src: url('/fonts/CabinSketch-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'helvetica';
    src: url('/fonts/Helvetica.ttf') format('truetype');
}

@font-face {
    font-family: 'museo-sans';
    src: url('/fonts/MuseoSans-300.otf') format('opentype');
}

@font-face {
    font-family: 'intermarche-regular';
    src: url('/fonts/itm-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'intermarche-bold';
    src: url('/fonts/itm-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'opensans';
    src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'mtf-toast';
    src: url('/fonts/mtf_toast.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 100;
    src: url('/fonts/RobotoCondensed-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/RobotoCondensed-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirNextCondensed';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/avenir_next_condensed_2.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirNextItProCondensed';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/avenir-next-lt-pro-bold-condensed.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirNextItProHeavy';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/avenir-next-lt-pro-heavy-condensed.otf') format('opentype');
}

@font-face {
    font-family: 'Klein';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Klein-Condensed-ExtraBold-trial.ttf') format('truetype');
}

@font-face {
    font-family: 'Ashery';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Ashery-AL9jp.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirBlack';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Avenir-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Branding SF Narrow SemiLight';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/Branding SF Narrow SemiLight.otf') format('opentype');
}

@font-face {
    font-family: 'Branding SF Narrow Medium';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Branding SF Narrow Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Branding SF Narrow SemiBold';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Branding SF Narrow SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'Branding SF Narrow Bold';
    font-style: bold;
    font-weight: 600;
    src: url('/fonts/Branding SF Narrow Bold.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaSB';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/Proxima Nova Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaBold';
    font-style: bold;
    font-weight: 600;
    src: url('/fonts/ProximaNova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaLight';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/ProximaNova-Light.otf') format('opentype');
}

@font-face {
    font-family: 'SanaSansBlack';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/Sana Sans Alt W00 Black.ttf') format('truetype');
}

@font-face {
    font-family: 'SanaSansBook';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/Sana Sans Alt W00 Book.ttf') format('truetype');
}

@font-face {
    font-family: 'TypoAuchan';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/Typo-auchan.ttf') format('truetype');
}

@font-face {
    font-family: 'UbuntuBold';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gill Sans Bold Italic';
    src: url('/fonts/Gill Sans Bold Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Bold';
    src: url('/fonts/Gill Sans Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GillSans Condensed Bold';
    src: url('/fonts/GillSans Condensed Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GillSans Condensed';
    src: url('/fonts/GillSans Condensed.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Heavy Italic';
    src: url('/fonts/Gill Sans Heavy Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Heavy';
    src: url('/fonts/Gill Sans Heavy.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Italic';
    src: url('/fonts/Gill Sans Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Light Italic';
    src: url('/fonts/Gill Sans Light Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Light';
    src: url('/fonts/Gill Sans Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Medium Italic';
    src: url('/fonts/Gill Sans Medium Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans Medium';
    src: url('/fonts/Gill Sans Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Gill Sans';
    src: url('/fonts/Gill Sans.otf') format('opentype');
}

@keyframes tpFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes tpZoomFadeIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tpBlinkWhite {
    0% {
        color: inherit;
    }

    49% {
        color: inherit;
    }
    50% {
        color: white;
    }

    99% {
        color: white;
    }
    100% {
        color: inherit;
    }
}

@keyframes tpBlinkBlack {
    0% {
        color: inherit;
    }

    49% {
        color: inherit;
    }
    50% {
        color: black;
    }

    99% {
        color: black;
    }
    100% {
        color: inherit;
    }
}
