@font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/avenir_next_condensed_2.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirBold';
    src: url('/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirHeavy';
    src: url('/fonts/avenir-next-lt-pro-heavy-condensed.otf') format('opentype');
}

@font-face {
    font-family: 'AvenirMedium';
    src: url('/fonts/Avenir-Medium.ttf') format('truetype');
}

.Franprix {
    text-align: center;
    font-family: 'AvenirNext';
    background-color: #f05f18;
    position: relative;
}

.Franprix span,
.Franprix p,
.Franprix h1,
.Franprix h2,
.Franprix h3,
.Franprix h4 {
    font-family: inherit;
}

.Franprix .ctr {
    background-color: #fff;
    height: calc(100% - 54px);
    width: calc(100% - 54px);
    margin: 27px;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 10px;
}

.Franprix .fpx-logo {
    position: absolute;
    left: 56px;
    top: 60px;
    width: 92px;
}

.Franprix.portrait,
.Franprix.portrait_direct {
    height: 1920px;
    width: 1080px;

    /* background-image: url(/templates/superu_portrait_bg.jpg); */
    background-size: 100% 100%;
    position: relative;
}

.Slide .PreviewFruitContainer .Franprix.portrait,
.Slide .PreviewFruitContainer .Franprix.portrait_direct {
    height: 1870px;
    width: 1030px;
}

.canvas .Franprix.portrait,
.canvas .Franprix.portrait_direct {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
}

.canvas .Franprix .ctr {
    height: calc(100% - 104px);
    width: calc(100% - 104px);
    margin: 52px;
}

.Franprix .promo {
    position: absolute;
    top: 60px;
    right: 0;
    width: 611px;
}

.Franprix .promo .promoBlock {
    display: flex;
    background-color: #f05f18;
    border-radius: 14px;
    color: #fff;
    font-size: 101px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 174px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.Franprix .promo p {
    font-size: 32px;
    font-weight: 900;
    color: #f05f18;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
    margin-top: 24px;
    line-height: 28px;
    font-family: 'AvenirBold';
    text-align: center;
}

.Franprix .data-price {
    position: absolute;
    left: 66px;
    top: 325px;
    color: #f05f18;
    font-family: 'AvenirBold';
}

.Franprix .data-price span.kilo-price {
    font-family: 'AvenirBold';
}

.Franprix .data-price .price {
    display: flex;
    justify-content: center;
}

.Franprix .data-price .price .num {
    font-size: 269px;
    font-weight: 900;
    letter-spacing: -15px;
    margin-right: 5px;
    line-height: 240px;
}

.Franprix .data-price .price .sec-part {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    align-items: flex-start;
    justify-content: flex-start;
}

.Franprix .data-price .price .sec-part .eur {
    font-size: 103px;
    font-weight: 900;
    line-height: 80px;
    text-align: left;
}

.Franprix .data-price .price .sec-part .cts {
    font-size: 105px;
    font-weight: 900;
    line-height: 165px;
    letter-spacing: -12.6px;
}

.Franprix .data-price .price-ctr,
.Franprix .data-price .kilo-price {
    font-size: 40.5px;
    font-weight: 900;
    text-transform: uppercase;
}

.Franprix .origin {
    position: absolute;
    left: 721px;
    top: 697px;
    font-family: 'Branding SF Narrow Medium';
    color: #000;
}

.Franprix .origin .origin-ctr {
    display: flex;
    font-size: 64px;
    align-items: center;
}

.Franprix .origin .origin-ctr span {
    margin-right: -24px;
    transform: scaleX(0.8);
    transform-origin: top left;
}

.Franprix .origin .origin-ctr img {
    max-height: 36px;
}

.Franprix .origin h5 {
    font-size: 78px;
    line-height: 0.85;
    text-align: left;
    text-transform: uppercase;
    transform: scaleX(0.55);
    transform-origin: top left;
    margin: 10px 0;
    font-weight: 300;
    color: #000;
}

.Franprix .image {
    position: absolute;
    left: 64px;
    top: 683px;
    height: 633px;
    width: 633px;
}

.Franprix .image img {
    max-height: 100%;
    max-width: 100%;
}

.Franprix .data {
    position: absolute;
    left: 77px;
    bottom: 34px;
    color: #000;
    text-align: left;
}

.Franprix .data h1 {
    font-size: 116px;
    font-weight: 900;
    letter-spacing: -1.16px;
    line-height: 0.89;
    margin: 0;
}

.Franprix .data p {
    font-size: 45px;
    font-weight: 300;
    letter-spacing: -1.07px;
    line-height: 40px;
    margin-top: 16px;
    font-family: 'AvenirHeavy';
}

.Franprix .data p strong {
    font-weight: 300;
    font-family: 'AvenirMedium';
}

.Franprix .data .flex-bakery {
    display: flex;
    gap: 25px;
}

.Franprix .data .flex-bakery > .card {
    padding: 0;
    box-shadow: none;
    margin: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Franprix .data .flex-bakery > .card p {
    font-size: 24px;
}

.Franprix .data .flex-bakery img {
    max-width: 40px;
}

.Franprix .labels {
    position: absolute;
    bottom: 24px;
    right: 12px;
    display: block;
}

.Franprix .labels img {
    margin: 0 12px;
    min-height: 105px;
    max-height: 105px;
}
