.emailfornews td {
    margin-bottom: 10px;
    border:1px solid #ddd;
    height: 5%;
}

.emailfornews {
    margin-bottom: 10px;
}

.myemail {
    height: 50vh;
    overflow-y: scroll;
}
