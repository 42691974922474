@font-face {
    font-family: 'InterstateCondensedBlack';
    src: url('/fonts/interstatecondblack.ttf') format('truetype');
}

@font-face {
    font-family: 'InterstateBold';
    src: url('/fonts/Interstate Bold.otf') format('opentype');
}

@font-face {
    font-family: 'InterstateLightCompressed';
    src: url('/fonts/Interstate LightCompressed.ttf') format('truetype');
}

@font-face {
    font-family: 'InterstateCondensedBold';
    src: url('/fonts/Interstate BoldCondensed.ttf') format('truetype');
}

.Intermarche.portrait {
    height: 1920px;
    width: 1080px;
}

.Intermarche {
    color: #000;
    text-align: center;
}

.Intermarche span,
.Intermarche p,
.Intermarche h1,
.Intermarche h2,
.Intermarche h3,
.Intermarche h4 {
    font-family: inherit;
}

.Slide .PreviewFruitContainer .Intermarche.portrait {
    height: 1870px;
    width: 1030px;
}

.canvas .Intermarche.portrait {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
}

.Intermarche .itm-logo {
    position: absolute;
    top: 106px;
    left: calc(50% - 215px);
    width: 100%;
    text-align: center;
    width: 430px;
    display: block;
}

.Intermarche .origin {
    top: 1035px;
    left: 98px;
    position: absolute;
    font-family: 'InterstateBold';
    font-size: 53.5px;
    color: #000;
    line-height: 40px;
}

.Intermarche .origin .origin-ctr {
    display: flex;
    align-items: center;
}

.Intermarche .origin img {
    max-height: 26px;
    margin-left: 20px;
}

.Intermarche .origin h5 {
    font-family: 'InterstateLightCompressed';
    font-size: 111px;
    font-weight: 200;
    line-height: 0.91;
    text-transform: uppercase;
    max-width: 350px;
    text-align: left;
    word-wrap: break-word;
    margin: 10px 0;
}

.Intermarche .image {
    position: absolute;
    top: 490px;
    left: 79px;
}

.Intermarche .image img {
    width: 515px;
    height: 515px;
}

.Intermarche .data-price {
    position: absolute;
    top: 348px;
    left: 569px;
    width: 438px;
    height: 438px;
    background-color: #ed1c24;
    border-radius: 50%;
    color: #fff;
    font-size: 118px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Intermarche .data-price .price {
    font-family: 'InterstateCondensedBlack';
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 95px;
}

.Intermarche .data-price .num {
    font-size: 231px;
    line-height: 200px;
    letter-spacing: -10px;
}

.Intermarche .data-price .eur {
    font-family: 'ProximaNovaBold';
    line-height: 70px;
    font-size: 105px;
    margin-top: 15px;
}

.Intermarche .data-price .price .sec-part {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    align-items: flex-start;
}

.Intermarche .data-price .price .sec-part .cts {
    font-weight: 900;
    line-height: 105px;
    font-weight: normal;
    letter-spacing: -6px;
}

.Intermarche .data-price .price-ctr {
    font-size: 44.5px;
    font-family: 'InterstateCondensedBlack';
    text-transform: uppercase;
    max-width: 275px;
    line-height: 1.09;
    margin-top: 25px;
}

.Intermarche .data {
    position: absolute;
    top: 1035px;
    right: 110px;
    max-width: 430px;
    text-align: left;
}

.Intermarche .price-precision {
    position: absolute;
    top: 825px;
    left: 569px;
    width: 438px;
    font-family: 'InterstateCondensedBold';
    color: #ed1c24;
    font-size: 35px;
}

.Intermarche .price-precision .kilo-price {
    font-family: 'InterstateBold';
    font-size: 32px;
    text-transform: uppercase;
}

.Intermarche .data h1 {
    font-family: 'InterstateCondensedBold';
    font-size: 98px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
    margin-bottom: 25px;
}

.Intermarche .data p {
    font-family: 'InterstateCondensedBold';
    font-size: 46px;
    text-align: left;
    margin: 0;
    line-height: 49px;
}

.Intermarche .bg {
    background-image: url('/templates/itm-kraft.jpg');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 342px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Intermarche .bg .promo-text {
    background-color: #ed1c24;
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    color: #fefefe;
    font-family: 'InterstateCondensedBlack';
    font-size: 153px;
    text-transform: uppercase;
}

.Intermarche .bg p {
    font-size: 38px;
    font-weight: 900;
    font-family: 'InterstateCondensedBlack';
    letter-spacing: 9px;
    font-style: italic;
    text-transform: uppercase;
    min-height: 59px;
    line-height: 1.6;
}

.Intermarche .promo {
    position: absolute;
    top: 240px;
    width: 100%;
}

.Intermarche .promo p {
    font-family: 'InterstateCondensedBlack';
    color: #ed1c24;
    font-size: 41px;
    text-transform: uppercase;
}

.Intermarche .labels {
    position: absolute;
    top: 24px;
    left: 24px;
    bottom: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    margin: -16px 0;
    align-items: center;
}

.Intermarche .labels img {
    max-height: 155px;
    margin: 16px 0;
    max-width: 200px;
}

.Intermarche .data .flex-bakery {
    display: flex;
    gap: 25px;
}

.Intermarche .data .flex-bakery > .card {
    padding: 0;
    box-shadow: none;
    margin: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
}

.Intermarche .data .flex-bakery > .card p {
    font-size: 24px;
}

.Intermarche .data .flex-bakery img {
    max-width: 40px;
}
