.off-div {
  background-color: #DF676E;
  color: white;
  padding: 4px 10px 4px 10px;
  border-radius: 5px
}

.filter-padding {
  padding-right: 10px;
}

.gm-style-iw button {
  position: 'absolute' !important;
  top: -5px !important;
  right: 5px !important;
}

.gm-style-iw button img {
  background: white;
  color: white;
  filter: invert(100%);
  border-radius: 25px;
  width: 25px !important;
  height: 25px !important;
}

.separatingBar {
  margin: 15px;
  width: 100%;
  height: 1px;
  background: #c9c9c9;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw.gm-style-iw-c {
  padding: 22px 12px 12px 12px !important;
  max-width: 275px !important;
  width: 275px !important;
}

.gm-style-iw.gm-style-iw-c .gm-style-iw-d{
  max-width: 275px !important;
}

.screenTitle {
  padding-bottom: 10px;
}

.screenIcon {
  padding: 10px !important;
}


.groupofmap {
  margin: 20px;
}