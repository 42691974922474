@font-face {
    font-family: 'EuroSans';
    src: url('/fonts/EuroSansStd-Bold_0.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSlab-1000';
    src: url('/fonts/MuseoSlab-1000_0.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSlab-900';
    src: url('/fonts/MuseoSlab-900_0.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSlab-700';
    src: url('/fonts/MuseoSlab-700_0.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSlab-300';
    src: url('/fonts/MuseoSlab-300_0.otf') format('opentype');
}

.UTemplate {
    text-align: center;
}

.UTemplate.portrait {
    height: 1920px;
    width: 1080px;

    background-image: url(/templates/superu_portrait_bg.jpg);
    background-size: 100% 100%;
    position: relative;
}

.Slide .PreviewFruitContainer .UTemplate.portrait {
    height: 1870px;
    width: 1030px;
}

.UTemplate.portrait.uexpress {
    background-image: url(/templates/uexpress_portrait_bg.jpg);
}

.UTemplate.portrait.hyperu {
    background-image: url(/templates/hyperu_portrait_bg.png);
}

.UTemplate.portrait.utile {
    background-image: url(/templates/utile_portrait_bg.png);
}

.canvas .UTemplate.portrait {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
}

.UTemplate.portrait .image {
    position: absolute;
    top: 285px;
    left: 0;
    width: 100%;
    height: 670px;
}

.UTemplate .image img {
    position: relative;
    max-height: 100%;
}

.UTemplate .price {
    position: absolute;
    background-image: url(/templates/superu_price_bg.png);
    background-size: 100% 100%;
    border-radius: 50%;
    height: 420px;
    width: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.UTemplate .price .actualPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
}

.UTemplate .price .quantity,
.UTemplate .price .quantity span {
    color: #faed4d;
    font-family: 'MuseoSlab-900';
    font-size: 54px;
    line-height: 1;
}

.UTemplate .price .quantity span {
    font-size: 34px;
}

.UTemplate .price .quantity .qContainer {
    line-height: 1;
    font-size: 54px;
}

.UTemplate.portrait .price {
    top: 780px;
    left: 90px;
}

.UTemplate .data {
    position: absolute;
    left: 500px;
    width: 580px;
    top: calc(740px + 210px - 31px);
    font-family: 'MuseoSlab-700';
    padding-left: 50px;
    color: #828586;
    text-align: left;
    font-size: 58px;
    box-sizing: border-box;
}

.UTemplate.portrait .data p {
    font-size: 50px;
}

.canvas .UTemplate.portrait .data {
    left: calc(540px - 50px);
}

.UTemplate.portrait .data .infos {
    margin-top: 0;
}

.UTemplate .data h1 {
    margin: 0;
    color: #eb2e27;
    font-size: 62px;
    text-transform: uppercase;
    font-family: 'MuseoSlab-900';
}

.UTemplate .data p {
    line-height: 70px;
}

.UTemplate .origin p.originLabel {
    color: black;
    font-size: 42px;
    position: absolute;
    top: -65px;
    left: 60%;
}

.UTemplate .origin .originContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    transform: translateX(min(calc(-305px + 40%), 0px));
    transform-origin: right;
}

.UTemplate .origin .originContainer.flag {
    transform: translateX(calc(-235px + 40%));
}

.UTemplate .flex-cols {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 42px;
    margin-left: 42px;
}

.UTemplate .origin {
    display: flex;
    position: absolute;
    top: 1350px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 48px;
}

.UTemplate .origin h2 {
    font-size: 124px;
    font-family: MuseoSlab-300;
    text-transform: uppercase;
    margin: 0;
    color: black;
    line-height: 1;
    transform: scaleX(0.4);
    transform-origin: right;
    max-width: calc(1080px * 1.9);
    text-align: end;
    width: max-content;
}

.UTemplate .origin .countryFlag,
.UTemplate .origin .u-label {
    object-fit: cover;
    display: block;
    width: 75px;
    height: 100%;
}

.UTemplate .origin .u-label {
    margin-left: 24px;
}

.UTemplate span.int {
    color: #faed4d;
    font-family: 'MuseoSlab-1000';
    font-size: 180px;
}

.UTemplate span.eur {
    font-size: 80px;
    font-family: 'EuroSans';
    color: #faed4d;
    margin-bottom: 135px;
    margin-right: -55px;
}

.UTemplate span.dec {
    font-family: 'MuseoSlab-1000';
    color: #faed4d;
    font-size: 100px;
    margin-top: 60px;
}

.UTemplate .infos .flex-bakery {
    display: flex;
    justify-content: space-evenly;
    max-height: 145px;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 390px;
    right: 150px;
    width: calc(1080px - 300px);
}

.UTemplate .infos .flex-bakery .card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
}

.UTemplate .infos .flex-bakery .card img {
    width: 70px;
    height: 70px;
}

.UTemplate .infos .flex-bakery .card p {
    font-size: 34px;
    text-align: center;
    color: black;
}

.UTemplate.bakery .infos > p,
.UTemplate.fishShop .infos > p {
    font-size: 34px;
    line-height: 1;
}

/* Landscape */
.UTemplate.landscape {
    width: 1920px;
    height: 1080px;

    background-image: url(/templates/superu_landscape_bg.jpg);
    background-size: 100% 100%;
}

.UTemplate.landscape.uexpress {
    background-image: url(/templates/uexpress_landscape_bg.jpg);
}

.UTemplate.landscape.hyperu {
    background-image: url(/templates/hyperu_landscape_bg.png);
}

.UTemplate.landscape.utile {
    background-image: url(/templates/utile_landscape_bg.png);
}

.canvas .UTemplate.landscape {
    width: calc(1920px - 50px);
    height: calc(1080px - 50px);
}

.UTemplate.landscape .image {
    position: absolute;
    top: 275px;
    left: 0;
    width: 750px;
}

.UTemplate.landscape .image img {
    position: relative;
    max-width: 100%;
    max-height: none;
}

.UTemplate.landscape .price {
    top: 380px;
    left: 750px;
}

.UTemplate.landscape .data .flex-cols h2 {
    max-width: 365px;
}

.UTemplate.landscape .data {
    position: absolute;
    top: 380px;
    left: 1170px;
    font-family: 'MuseoSlab-700';
    padding-left: 50px;
    color: #828586;
    text-align: left;
    font-size: 58px;
    width: 745px;
    box-sizing: border-box;
}

.UTemplate.landscape .data p {
    font-size: 58px;
}

.canvas .UTemplate.landscape .data {
    width: calc(745px - 50px);
}

.UTemplate.landscape .origin {
    top: 780px;
    right: 0;
}

.UTemplate.landscape .origin h2 {
    text-align: left;
    transform-origin: right;
}

.UTemplate .promo {
    height: 240px;
    width: 240px;
    background-color: #eb2e27;
    border-radius: 50%;
    position: absolute;
    top: 325px;
    left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid #faed4d;
    animation: blinker 2.4s ease-in-out infinite;
}

.UTemplate .promo h3,
.UTemplate .promo h4,
.dyl-home .UTemplate .promo h4 {
    color: #faed4d;
    text-transform: uppercase;
    font-size: 50px;
    font-family: 'MuseoSlab-900';
    margin: 0;
    line-height: 1;
}

.UTemplate .promo h3 {
    font-size: 70px;
}

.UTemplate .promo h4 {
    transform: scaleX(0.9);
    transform-origin: center;
}

.UTemplate .expirationDate {
    text-transform: uppercase;
    color: #fff;
    font-family: 'MuseoSlab-900';
    font-size: 28px;
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    text-align: center;
}

.UTemplate.landscape .expirationDate {
    top: 12px;
}

.UTemplate.landscape .origin .originContainer {
    transform: translateX(min(calc(-505px + 40%), 0px));
}

.UTemplate.landscape .origin .originContainer.flag {
    transform: translateX(calc(-435px + 40%));
}

.UTemplate.landscape .infos .flex-bakery {
    top: 375px;
}

/* Led formats */
.UTemplate.led_288_416 {
    width: 288px;
    height: 416px;
    position: relative;
}

.UTemplate.led_288_416 .image {
    position: absolute;
    top: 10px;
    left: 0;
    width: 288px;
}

.UTemplate.led_288_416 .image img {
    width: 180px;
}

.UTemplate.led_288_416 .price {
    height: 100px;
    width: 100px;
    top: 150px;
    left: 10px;
}

.UTemplate.led_288_416 .origin {
    top: 295px;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
}

.UTemplate.led_288_416 span.int {
    font-size: 42px;
}

.UTemplate.led_288_416 span.eur {
    font-size: 18px;
    margin-bottom: 30px;
    margin-right: -10px;
}

.UTemplate.led_288_416 span.dec {
    font-size: 24px;
    margin-top: 10px;
}

.UTemplate.led_288_416 .price .quantity {
    font-size: 14px;
}

.UTemplate.led_288_416 .data {
    left: 110px;
    top: 190px;
    padding-left: 10px;
    font-size: 12px;
    width: 178px;
}

.UTemplate.led_288_416 .data h1 {
    font-size: 24px;
}

.UTemplate.led_288_416 .data p {
    line-height: 16px;
}

.UTemplate.led_288_416 .origin p.originLabel {
    font-size: 18px;
    top: -26px;
    left: 0;
}

.UTemplate.led_288_416 .origin h2 {
    font-size: 46px;
    transform-origin: left;
}

.UTemplate.led_288_416 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_288_416 .origin .countryFlag,
.UTemplate.led_288_416 .origin .u-label {
    width: 25px;
}

.UTemplate.led_288_416 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_288_416 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_288_416::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 25px);
    background-repeat: no-repeat;
    background-size: cover;
}

.UTemplate.led_288_416 .promo {
    transform: scale(0.2);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_288_416 .expirationDate {
    top: 10px;
    top: auto;
    font-size: 12px;
    color: #eb2e27;
}

/* ------------------ */

.UTemplate.led_192_272 {
    width: 192px;
    height: 272px;
    position: relative;
}

.UTemplate.led_192_272 .image {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
}

.UTemplate.led_192_272 .image img {
    width: 160px;
}

.UTemplate.led_192_272 .price {
    height: 70px;
    width: 70px;
    top: 120px;
    left: 7px;
}

.UTemplate.led_192_272 span.int {
    font-size: 32px;
}

.UTemplate.led_192_272 span.eur {
    font-size: 12px;
    margin-bottom: 24px;
    margin-right: -8px;
}

.UTemplate.led_192_272 span.dec {
    font-size: 16px;
    margin-top: 10px;
}

.UTemplate.led_192_272 .price .quantity,
.UTemplate.led_192_272 .price .quantity span {
    font-size: 12px;
}

.UTemplate.led_192_272 .price .quantity .qContainer {
    font-size: 10px;
}

.UTemplate.led_192_272 .data {
    left: 75px;
    top: 145px;
    padding-left: 10px;
    width: 117px;
}

.UTemplate.led_192_272 .data h1 {
    font-size: 18px;
}

.UTemplate.led_192_272 .data p {
    line-height: 12px;
    font-size: 10px;
}

.UTemplate.led_192_272 .origin {
    top: 228px;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
}

.UTemplate.led_192_272 .origin p.originLabel {
    font-size: 12px;
    top: -15px;
    left: 0;
}

.UTemplate.led_192_272 .origin h2 {
    font-size: 26px;
    transform-origin: left;
}

.UTemplate.led_192_272 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_192_272 .origin .countryFlag,
.UTemplate.led_192_272 .origin .u-label {
    width: 25px;
}

.UTemplate.led_192_272 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_192_272 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_192_272::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 10px;
    width: 10px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 5px);
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
}

.UTemplate.led_192_272 .promo {
    transform: scale(0.15);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_192_272 .expirationDate {
    top: 10px;
    top: auto;
    font-size: 9px;
    color: #eb2e27;
}

/* ------------------- */

.UTemplate.led_448_340 {
    width: 448px;
    height: 340px;
    position: relative;
}

.UTemplate.led_448_340 .image {
    position: absolute;
    top: 74px;
    left: 0;
    width: 50%;
}

.UTemplate.led_448_340 .image img {
    width: 190px;
}

.UTemplate.led_448_340 .price {
    height: 110px;
    width: 110px;
    top: 115px;
    left: 180px;
}

.UTemplate.led_448_340 span.int {
    font-size: 42px;
}

.UTemplate.led_448_340 span.eur {
    font-size: 14px;
    margin-bottom: 28px;
    margin-right: -5px;
}

.UTemplate.led_448_340 span.dec {
    font-size: 22px;
    margin-top: 12px;
}

.UTemplate.led_448_340 .price .quantity,
.UTemplate.led_448_340 .price .quantity span {
    font-size: 16px;
}

.UTemplate.led_448_340 .price .quantity .qContainer {
    font-size: 14px;
}

.UTemplate.led_448_340 .data {
    left: auto;
    right: 0;
    top: 125px;
    padding-left: 10px;
    width: 154px;
}

.UTemplate.led_448_340 .data h1 {
    font-size: 24px;
}

.UTemplate.led_448_340 .data p {
    line-height: 14px;
    font-size: 12px;
}

.UTemplate.led_448_340 .origin {
    top: 244px;
    left: 50%;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
    width: 50%;
}

.UTemplate.led_448_340 .origin p.originLabel {
    font-size: 12px;
    top: -15px;
    left: 0;
}

.UTemplate.led_448_340 .origin h2 {
    font-size: 36px;
    transform-origin: left;
}

.UTemplate.led_448_340 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_448_340 .origin .countryFlag,
.UTemplate.led_448_340 .origin .u-label {
    width: 25px;
}

.UTemplate.led_448_340 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_448_340 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_448_340::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 20px);
    background-repeat: no-repeat;
    background-size: cover;
}

.UTemplate.led_448_340 .promo {
    transform: scale(0.2);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_448_340 .expirationDate {
    top: 5px;
    font-size: 12px;
    color: #eb2e27;
}

/* ------------------- */

.UTemplate.led_336_255 {
    width: 336px;
    height: 225px;
    position: relative;
}

.UTemplate.led_336_255 .image {
    position: absolute;
    top: 36px;
    left: 0;
    width: 50%;
}

.UTemplate.led_336_255 .image img {
    width: 150px;
}

.UTemplate.led_336_255 .price {
    height: 90px;
    width: 90px;
    top: 75px;
    left: 120px;
}

.UTemplate.led_336_255 span.int {
    font-size: 36px;
}

.UTemplate.led_336_255 span.eur {
    font-size: 10px;
    margin-bottom: 25px;
    margin-right: -8px;
}

.UTemplate.led_336_255 span.dec {
    font-size: 15px;
    margin-top: 11px;
}

.UTemplate.led_336_255 .price .quantity,
.UTemplate.led_336_255 .price .quantity span {
    font-size: 14px;
}

.UTemplate.led_336_255 .price .quantity .qContainer {
    font-size: 11px;
}

.UTemplate.led_336_255 .data {
    left: auto;
    right: 0;
    top: 75px;
    padding-left: 10px;
    width: 124px;
}

.UTemplate.led_336_255 .data h1 {
    font-size: 18px;
}

.UTemplate.led_336_255 .data p {
    line-height: 14px;
    font-size: 10px;
}

.UTemplate.led_336_255 .origin {
    top: auto;
    bottom: 5px;
    left: 50%;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
    width: 50%;
}

.UTemplate.led_336_255 .origin p.originLabel {
    font-size: 12px;
    top: -15px;
    left: 0;
}

.UTemplate.led_336_255 .origin h2 {
    font-size: 26px;
    transform-origin: left;
}

.UTemplate.led_336_255 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_336_255 .origin .countryFlag,
.UTemplate.led_336_255 .origin .u-label {
    width: 25px;
}

.UTemplate.led_336_255 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_336_255 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_336_255::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 10px;
    left: calc(40% - 15px);
    background-repeat: no-repeat;
    background-size: cover;
}

.UTemplate.led_336_255 .promo {
    transform: scale(0.16);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_336_255 .expirationDate {
    top: 5px;
    font-size: 12px;
    color: #eb2e27;
}

/* ------------------- */

.UTemplate.led_768_204 {
    width: 768px;
    height: 204px;
    position: relative;
}

.UTemplate.led_768_204 .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
}

.UTemplate.led_768_204 .image img {
    width: 200px;
}

.UTemplate.led_768_204 .price {
    height: 110px;
    width: 110px;
    top: 35px;
    left: 330px;
}

.UTemplate.led_768_204 span.int {
    font-size: 48px;
}

.UTemplate.led_768_204 span.eur {
    font-size: 10px;
    margin-bottom: 25px;
    margin-right: -8px;
}

.UTemplate.led_768_204 span.dec {
    font-size: 21px;
    margin-top: 15px;
}

.UTemplate.led_768_204 .price .quantity,
.UTemplate.led_768_204 .price .quantity span {
    font-size: 18px;
}

.UTemplate.led_768_204 .price .quantity .qContainer {
    font-size: 14px;
}

.UTemplate.led_768_204 .data {
    left: auto;
    right: 0;
    top: 45px;
    padding-left: 10px;
    width: 224px;
}

.UTemplate.led_768_204 .data h1 {
    font-size: 24px;
}

.UTemplate.led_768_204 .data p {
    line-height: 16px;
    font-size: 14px;
}

.UTemplate.led_768_204 .origin {
    top: auto;
    bottom: 5px;
    left: 60%;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
    width: 40%;
}

.UTemplate.led_768_204 .origin p.originLabel {
    font-size: 12px;
    top: -15px;
    left: 0;
}

.UTemplate.led_768_204 .origin h2 {
    font-size: 36px;
    transform-origin: left;
}

.UTemplate.led_768_204 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_768_204 .origin .countryFlag,
.UTemplate.led_768_204 .origin .u-label {
    width: 25px;
}

.UTemplate.led_768_204 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_768_204 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_768_204::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 20px);
    background-repeat: no-repeat;
    background-size: cover;
}

.UTemplate.led_768_204 .promo {
    transform: scale(0.2);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_768_204 .expirationDate {
    top: 5px;
    font-size: 12px;
    color: #eb2e27;
}

/* ------------------- */

.UTemplate.led_576_153 {
    width: 576px;
    height: 153px;
    position: relative;
}

.UTemplate.led_576_153 .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
}

.UTemplate.led_576_153 .image img {
    width: 150px;
}

.UTemplate.led_576_153 .price {
    height: 95px;
    width: 95px;
    top: 23px;
    left: 240px;
}

.UTemplate.led_576_153 span.int {
    font-size: 36px;
}

.UTemplate.led_576_153 span.eur {
    font-size: 10px;
    margin-bottom: 25px;
    margin-right: -8px;
}

.UTemplate.led_576_153 span.dec {
    font-size: 15px;
    margin-top: 11px;
}

.UTemplate.led_576_153 .price .quantity,
.UTemplate.led_576_153 .price .quantity span {
    font-size: 14px;
}

.UTemplate.led_576_153 .price .quantity .qContainer {
    font-size: 11px;
}

.UTemplate.led_576_153 .data {
    left: auto;
    right: 0;
    top: 15px;
    padding-left: 10px;
    width: 170px;
}

.UTemplate.led_576_153 .data h1 {
    font-size: 18px;
}

.UTemplate.led_576_153 .data p {
    line-height: 14px;
    font-size: 12px;
}

.UTemplate.led_576_153 .origin {
    top: auto;
    bottom: 5px;
    left: 60%;
    margin-top: 0;
    justify-content: space-between;
    padding-left: 10px;
    width: 40%;
}

.UTemplate.led_576_153 .origin p.originLabel {
    font-size: 12px;
    top: -15px;
    left: 0;
}

.UTemplate.led_576_153 .origin h2 {
    font-size: 36px;
    transform-origin: left;
}

.UTemplate.led_576_153 .flex-cols {
    padding-right: 12px;
    margin-left: 12px;
}

.UTemplate.led_576_153 .origin .countryFlag,
.UTemplate.led_576_153 .origin .u-label {
    width: 25px;
}

.UTemplate.led_576_153 .origin .u-label {
    margin-left: 15px;
}

.UTemplate.led_576_153 .origin .originContainer {
    transform: none;
    width: 0;
}

.UTemplate.led_576_153::after {
    content: '';
    background-image: url(/templates/ulogo.svg);
    height: 24px;
    width: 24px;
    position: absolute;
    bottom: 5px;
    left: calc(50% - 12px);
    background-repeat: no-repeat;
    background-size: cover;
}

.UTemplate.led_576_153 .promo {
    transform: scale(0.2);
    transform-origin: top left;
    top: 10px;
    left: 10px;
}

.UTemplate.UTemplate.led_576_153 .expirationDate {
    top: 5px;
    font-size: 12px;
    color: #eb2e27;
}
