.passForgot {
    cursor: pointer;
    text-align: right;
    font-size: 9px;
    width: auto;
    margin-left: auto;
    float: right;
}

.ContactDeleteMessage {
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
}

.AssetsHeader {
    margin: -25px;
    background-color: #eee;
    padding: 25px 0;
    margin-bottom: 25px;
    position: relative;
}

.AssetsHeader h4 {
    margin: 0;
}

.AssetsHeader .searchBox {
    position: absolute;
    display: inline-block;
    top: 18px;
    left: 15px;
}

.AssetsHeader .buttons {
    position: absolute;
    top: 18px;
    right: 15px;
}

.AssetsPanelFolders .panel-body {
    min-height: 25px;
}

.AssetsPanelFolders .folderSelection {
    text-align: center;
}

.AssetsPanelFolders .folderSelection .folder {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0 0 0;
    position: relative;
}

.AssetsPanelFolders .folderSelection .folder.active {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.AssetsPanelFolders .folderSelection .folder i {
    font-size: 100px;
    transition: color 0.2s ease;
    color: #cca555;
}

.AssetsPanelFolders .folderSelection .folder span.nb {
    color: #fff;
    position: absolute;
    left: 50%;
    text-align: center;
    top: 38%;
    font-size: 24px;
    user-select: none;
    transform: translateX(-50%);
    cursor: pointer;
}

.AssetsPanelFolders .folderSelection .folder span.nb.search {
    color: #6f97e0;
}

.AssetsPanelFolders .folderSelection .folder i:hover {
    color: #e4ba62;
    cursor: pointer;
}

.AssetsPanelFolders .return {
    position: absolute;
    top: 0;
    left: 15px;
    height: 25px;
    width: 25px;
    background-color: rgb(66, 66, 66);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    cursor: pointer;
    z-index: 99;
}

.AssetsPanelFolders .return:hover {
    background-color: rgb(99, 99, 99);
}

.AssetsPanelFolders .return i {
    line-height: 25px;
}

@media (max-width: 425px) {
    .AssetsHeader {
        position: fixed;
        width: calc(100% - 48px);
        z-index: 9;
        top: 25px;
        border-top: 8px solid #f7f9fa;
    }

    .AssetsHeader .buttons {
        position: initial;
        display: block;
        text-align: center;
        margin-top: 1em;
        margin-bottom: calc(1em + 16px);
    }

    .AssetsBody {
        padding-top: 142px;
    }

    .EditorModal .AssetsBody {
        padding-top: 0;
        max-height: calc(80vh - 100px) !important;
        overflow-y: inherit !important;
    }

    .AssetsHeader .searchBox {
        display: block;
        position: absolute;
        bottom: 12px;
        top: auto;
        left: 50%;
        transform: translateX(-50%);
    }

    .AssetsHeader .searchBox input {
        margin: 0;
    }

    .AssetsPanelFolders .return {
        display: none;
    }

    .AssetsPanelFolders .backButton {
        position: fixed;
        left: 46px;
        z-index: 10;
        top: 27px;
        font-size: 21px;
    }
}

.AssetsBody {
    max-height: calc(80vh - 100px);
    overflow-y: scroll;
}

.AssetsBody::-webkit-scrollbar {
    display: none;
}

.AssetsBody {
    -ms-overflow-style: none;
}

@media (max-width: 320px) {
    .AssetsBody {
        padding-top: 175px;
    }

    .AssetsPanelFolders .folderSelection .folder {
        width: 96px;
    }

    .AssetsPanelFolders .return {
        top: 155px;
    }

    .AssetsPanelFolders .backButton {
        position: fixed;
        left: 54px;
        z-index: 10;
        top: 24px;
        font-size: 21px;
    }

    .AssetsHeader h4.AssetHeaderTitle {
        font-size: 12px;
    }
}

.modal-header h4.modal-small {
    padding: 0 !important;
}

.banner-camp {
    background-color: #f8495e;
    color: white;
    font-size: 24px;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.creaBlock {
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    padding-bottom: 7px;
}

.creaBlock .creaVid {
    width: 20%;
    max-width: 200px;
}

.creaBlock .msg {
    display: block;
    padding: 0 25px;
}

.creaBlock .msg h4 {
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px;
}

.creaBlock .msg strong {
    font-weight: 900;
}

.creaBlock .visible-mob {
    display: none;
}

.creaBlock .no-mob {
    display: block;
}

@media (max-width: 425px) {
    .creaBlock {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding-bottom: 15px;
    }

    .creaBlock .msg {
        display: block;
        padding: 0;
    }

    .creaBlock .visible-mob {
        display: block;
    }

    .creaBlock .no-mob {
        display: none;
    }

    .creaBlock .creaVid {
        width: 50%;
        max-width: 200px;
        justify-content: center;
        align-self: center;
    }
}

.horaires-screen-management .day-hours {
    display: flex;
    align-items: center;
}

.horaires-screen-management .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin: 0;
}

.horaires-screen-management .form-group label {
    margin: 0;
    margin-right: 10px;
}
