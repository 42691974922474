/* From old template */
.Fruits {
    height: 1920px;
    width: 1080px;
    display: block;
    position: relative;
}

.Fruits.olandscape {
    width: 1920px;
    height: 1080px;
}

.Fruits.ofl_simple {
    width: 1440px;
    height: 810px;
}

.Fruits.ofl_1680_946 {
    width: 1680px;
    height: 946px;
}

.Fruits.ofl_xl {
    width: 1680px;
    height: 1890px;
}

.Fruits.oring {
    width: 960px;
    height: 540px;
}

.Fruits.oled_961_577 {
    width: 961px;
    height: 577px;
}

/* End */

.MainTemplate {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto Condensed', sans-serif;
    overflow: hidden;
}

.MainTemplate.landscape {
    height: calc(1080px - 50px);
    width: calc(1920px - 50px);
}

.MainTemplate.led_1824_1056 {
    height: calc(1056px);
    width: calc(1824px);
}

.MainTemplate.fl_simple {
    height: 810px;
    width: 1440px;
}

.MainTemplate.fl_1680_946 {
    height: 1080px;
    width: 1920px;
}

.MainTemplate.fl_xl {
    height: calc(1890px - 50px);
    width: calc(1680px - 50px);
}

.MainTemplate.ring {
    height: 540px;
    width: 960px;
}

.MainTemplate.led_961_577 {
    height: 577px;
    width: 961px;
}

.MainTemplate * {
    box-sizing: border-box;
}

.MainTemplate .rayon {
    width: 250px;
    height: 190px;
    margin-left: 40px;
    padding-top: 10px;
    border-bottom: 8px solid #444;
    z-index: 2;
}

.MainTemplate.landscape .rayon,
.MainTemplate.led_1824_1056 .rayon,
.MainTemplate.fl_1680_946 .rayon,
.MainTemplate.fl_simple .rayon,
.MainTemplate.ring .rayon,
.MainTemplate.led_961_577 .rayon {
    align-self: flex-end;
    margin-right: 40px;
    margin-left: 0;
}

.MainTemplate.ring .rayon,
.MainTemplate.led_961_577 .rayon {
    height: 115px;
    width: 130px;
    margin-right: 10px;
}

.MainTemplate.fl_simple .rayon {
    width: 170px;
    height: 135px;
}

.MainTemplate .data {
    z-index: 2;
}

.MainTemplate h1,
.MainTemplate h2,
.MainTemplate h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', sans-serif;
}

.MainTemplate span,
.MainTemplate p {
    font-family: 'Roboto Condensed', sans-serif;
}

.MainTemplate .data .origin {
    text-align: right;
    margin-right: 40px;
    font-weight: 700;
}

.MainTemplate.ring .data .origin,
.MainTemplate.led_961_577 .data .origin {
    margin-right: 20px;
}

.MainTemplate .data .origin .title {
    display: flex;
    justify-content: flex-end;
}

.MainTemplate .data .origin .title span {
    display: inline-block;
    border-bottom: 4px solid #333;
    font-size: 30px;
    letter-spacing: 2px;
    padding-bottom: 10px;
    color: #333;
    vertical-align: text-top;
    text-transform: uppercase;
}

.MainTemplate.ring .data .origin .title span,
.MainTemplate.led_961_577 .data .origin .title span {
    font-size: 15px;
    padding-bottom: 5px;
}

.MainTemplate .data .origin .title img.countryFlag {
    height: 50px;
    margin-right: 20px;
    display: block;
}

.MainTemplate.ring .data .origin .title img.countryFlag,
.MainTemplate.led_961_577 .data .origin .title img.countryFlag {
    height: 25px;
}

.MainTemplate .data .origin h2 {
    color: #333;
    font-size: 160px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
}

.MainTemplate.ring .data .origin h2,
.MainTemplate.led_961_577 .data .origin h2 {
    font-size: 80px;
}

.MainTemplate.fl_1680_946 .data .origin h2 {
    max-width: 50%;
    margin-left: auto;
    font-size: 140px;
}

.MainTemplate.fl_simple .data .origin h2 {
    font-size: 110px;
}

.MainTemplate.fl_simple .data .origin h2 {
    font-size: 120px;
}

.MainTemplate.fl_xl .infos {
    justify-content: flex-end;
}

.MainTemplate .infos {
    display: flex;
}

.MainTemplate.landscape .infos,
.MainTemplate.led_1824_1056 .infos,
.MainTemplate.fl_1680_946 .infos,
.MainTemplate.fl_simple .infos,
.MainTemplate.ring .infos,
.MainTemplate.led_961_577 .infos {
    justify-content: flex-end;
}

.MainTemplate .infos .left {
    width: 760px;
    height: 480px;
    position: relative;
}

.MainTemplate.landscape .infos .left,
.MainTemplate.led_1824_1056 .infos .left,
.MainTemplate.fl_1680_946 .infos .left {
    width: 510px;
    height: 480px;
}

.MainTemplate.fl_simple .infos .left {
    width: 382px;
    height: 360px;
}

.MainTemplate.ring .infos .left,
.MainTemplate.led_961_577 .infos .left {
    width: 290px;
    height: 240px;
}

.MainTemplate .infos .id {
    flex: 1 0 auto;
    background: #333;
    padding: 40px 40px 85px 90px;
    color: #fff;
    text-align: left;
    height: 480px;
}

.MainTemplate.landscape .infos .id,
.MainTemplate.led_1824_1056 .infos .id,
.MainTemplate.fl_1680_946 .infos .id {
    padding: 40px;
    padding-left: 50px;
    height: 480px;
    padding-bottom: 85px;
}

.MainTemplate.ring .infos .id,
.MainTemplate.led_961_577 .infos .id {
    height: 240px;
    padding: 20px 20px 45px 25px;
}

.MainTemplate.fl_simple .infos .id {
    padding: 20px 20px 45px 25px;
    height: 360px;
}

.MainTemplate .promo {
    color: #fff;
    font-size: 120px;
    font-style: italic;
    padding: 0 30px 0 60px;
    transform: rotate(-9deg);
    margin-left: -20px;
    text-decoration: blink;
    z-index: 2;
    position: absolute;
    top: 270px;
    animation: blinker 2.4s ease-in-out infinite;
    text-transform: uppercase;
}

.MainTemplate.landscape .promo,
.MainTemplate.led_1824_1056 .promo,
.MainTemplate.fl_1680_946 .promo {
    top: 90px;
}

.MainTemplate.ring .promo,
.MainTemplate.led_961_577 .promo {
    top: 90px;
    font-size: 60px;
}

.MainTemplate.fl_simple .promo {
    top: 90px;
    font-size: 90px;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.MainTemplate .infos .id h1 {
    color: #fff;
    font-size: 90px;
    font-weight: 700;
    line-height: 1;
    margin: 10px 0;
}

.MainTemplate.ring .infos .id h1,
.MainTemplate.led_961_577 .infos .id h1 {
    font-size: 45px;
    margin: 0 0 5px 0;
}

.MainTemplate.landscape .infos .id h1,
.MainTemplate.led_1824_1056 .infos .id h1,
.MainTemplate.fl_1680_946 .infos .id h1,
.MainTemplate.ring .infos .id h1,
.MainTemplate.led_961_577 .infos .id h1 {
    margin-top: 0;
}

.MainTemplate.fl_simple .infos .id h1 {
    margin-top: 0;
    font-size: 67.5px;
}

.MainTemplate .infos .id p {
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 15px;
}

.MainTemplate.landscape .infos .id p,
.MainTemplate.led_1824_1056 .infos .id p,
.MainTemplate.fl_1680_946 .infos .id p {
    margin-bottom: 10px;
}

.MainTemplate.ring .infos .id p,
.MainTemplate.led_961_577 .infos .id p {
    font-size: 20px;
    margin-bottom: 5px;
}

.MainTemplate.fl_simple .infos .id p {
    margin-bottom: 5px;
    font-size: 30px;
}

.MainTemplate .infos .lasting {
    font-size: 40px;
    height: 65px;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #fff;
    letter-spacing: 4px;
    z-index: 2;
    position: absolute;
    width: 100%;
}

.MainTemplate.landscape .infos .lasting,
.MainTemplate.led_1824_1056 .infos .lasting,
.MainTemplate.fl_1680_946 .infos .lasting {
    width: 850px;
}

.MainTemplate.ring .infos .lasting,
.MainTemplate.led_961_577 .infos .lasting {
    height: 45px;
    font-size: 20px;
    width: 460px;
}

.MainTemplate.fl_simple .infos .lasting {
    width: 637px;
    font-size: 30px;
    height: 63.5px;
}

.MainTemplate .infos .price {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #222;
    width: 340px;
    padding: 60px 35px 40px;
    font-weight: 700;
    height: 480px;
    position: relative;
}

.MainTemplate.landscape .infos .price,
.MainTemplate.led_1824_1056 .infos .price,
.MainTemplate.fl_1680_946 .infos .price {
    padding-bottom: 85px;
    padding-top: 40px;
}

.MainTemplate.ring .infos .price,
.MainTemplate.led_961_577 .infos .price {
    width: 170px;
    height: 240px;
    padding: 20px 16px 45px 16px;
}

.MainTemplate.fl_simple .infos .price {
    padding-bottom: 85px;
    padding-top: 40px;
    width: 255px;
    height: 360px;
}

.MainTemplate .infos .price .value {
    width: 100%;
    margin-bottom: 90px;
}

.MainTemplate.landscape .infos .price .value,
.MainTemplate.led_1824_1056 .infos .price .value,
.MainTemplate.fl_1680_946 .infos .price .value {
    width: 100%;
    margin-bottom: 10px;
}

.MainTemplate.ring .infos .price .value,
.MainTemplate.led_961_577 .infos .price .value {
    margin-bottom: 10px;
}

.MainTemplate.fl_simple .infos .price .value {
    margin-bottom: 10px;
}

.MainTemplate .infos .price .crossedValue {
    position: absolute;
    top: 170px;
    width: calc(100% - 70px);
    color: #444;
}

.MainTemplate.led_1824_1056 .infos .price .crossedValue {
    position: absolute;
    top: 150px;
    width: calc(100% - 70px);
    color: #444;
}

.MainTemplate .infos .price .crossedValue span.int {
    font-size: 110px;
    text-align: left;
    line-height: 150px;
}

.MainTemplate .infos .price .crossedValue span.eur {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 30px;
    padding-top: 0px;
    margin-right: -8px;
    vertical-align: top;
    line-height: 1;
    position: absolute;
    top: 35px;
}

.MainTemplate .infos .price .crossedValue span.dec {
    display: inline-block;
    vertical-align: top;
    padding-top: 65px;
    font-size: 60px;
    line-height: 1;
    margin-left: 4px;
}

.MainTemplate .infos .price .crossedValue .bar {
    height: 8px;
    width: 150px;
    background-color: #222;
    position: absolute;
    top: 80px;
    left: 52px;
    transform: rotate(20deg);
}

.MainTemplate .infos .price .value span.int {
    font-size: 153px;
    text-align: left;
    line-height: 1;
}

.MainTemplate.fl_simple .infos .price .value span.int {
    font-size: 114px;
}

.MainTemplate.ring .infos .price .value span.int,
.MainTemplate.led_961_577 .infos .price .value span.int {
    font-size: 80px;
}

.MainTemplate .infos .price .value span.eur {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 40px;
    padding-top: 0px;
    margin-right: -15px;
    vertical-align: top;
    line-height: 1;
}

.MainTemplate.ring .infos .price .value span.eur,
.MainTemplate.led_961_577 .infos .price .value span.eur {
    font-size: 20px;
}

.MainTemplate.fl_simple .infos .price .value span.eur {
    font-size: 30px;
}

.MainTemplate .infos .price .value span.dec {
    display: inline-block;
    vertical-align: top;
    padding-top: 40px;
    font-size: 80px;
    line-height: 1;
}

.MainTemplate.ring .infos .price .value span.dec,
.MainTemplate.led_961_577 .infos .price .value span.dec {
    padding-top: 20px;
    font-size: 40px;
}

.MainTemplate.fl_simple .infos .price .value span.dec {
    padding-top: 30px;
    font-size: 60px;
}

.MainTemplate .infos .price .unit {
    color: #fff;
}

.MainTemplate.led_1824_1056 .infos .price {
    position: relative;
    display: block;
}

.MainTemplate.led_1824_1056 .infos .price .unit {
    position: absolute;
    bottom: 85px;
    width: 100%;
    left: 0px;
}

.MainTemplate .infos .price .contenant {
    font-size: 50px;
    margin-bottom: 10px;
    line-height: 50px;
}

.MainTemplate.ring .infos .price .contenant,
.MainTemplate.led_961_577 .infos .price .contenant {
    font-size: 25px;
    margin-bottom: 5px;
}

.MainTemplate.fl_simple .infos .price .contenant {
    font-size: 37.5px;
    margin-bottom: 5px;
}

.MainTemplate .infos .price .quantity {
    font-size: 60px;
    line-height: 65px;
}

.MainTemplate.ring .infos .price .quantity,
.MainTemplate.led_961_577 .infos .price .quantity {
    font-size: 30px;
}

.MainTemplate.fl_simple .infos .price .quantity {
    font-size: 40px;
}

.MainTemplate .label {
    position: absolute;
    height: 250px;
    margin-top: 40px;
    margin-right: 20px;
    right: 0;
    top: 0;
    z-index: 4;
    text-align: right;
}

.MainTemplate.landscape .label,
.MainTemplate.led_1824_1056 .label,
.MainTemplate.fl_1680_946 .label {
    left: 1030px;
    top: 0;
    text-align: left;
    max-width: 50%;
    margin: 0;
    margin-top: 10px;
}

.MainTemplate.fl_simple .label {
    left: 790px;
    top: 0;
    text-align: left;
    max-width: 50%;
    margin: 0;
    margin-top: 7px;
    height: 170px;
}

.MainTemplate.ring .label,
.MainTemplate.led_961_577 .label {
    left: 500px;
    top: 0;
    text-align: left;
    height: 125px;
    margin: 0;
    margin-top: 5px;
}

.MainTemplate .label img {
    max-height: 100%;
    max-width: 400px;
}

.MainTemplate.portrait .img-ctr,
.MainTemplate.portrait_direct .img-ctr {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1030px;
    top: 220px;
    z-index: 1;
    user-select: none;
    -webkit-user-drag: none;
}

.MainTemplate.fl_simple .img-ctr,
.MainTemplate.fl_1680_946 .img-ctr {
    position: absolute;
    top: 0;
    left: 0;
}

.MainTemplate.landscape img.mainImg,
.MainTemplate.led_1824_1056 img.mainImg {
    position: absolute;
    left: 0;
    width: 100%;
    top: 220px;
    z-index: 1;
    user-select: none;
    -webkit-user-drag: none;
}

.MainTemplate.product img.mainImg,
.MainTemplate.caterer img.mainImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.MainTemplate.product img.mainImg {
    padding: 150px;
}

.MainTemplate.landscape img.mainImg,
.MainTemplate.led_1824_1056 img.mainImg,
.MainTemplate.fl_1680_946 img.mainImg {
    width: 1030px;
    top: 0;
    left: 0;
}

.MainTemplate.fl_simple img.mainImg {
    width: 790px;
    top: 0;
    left: 0;
}

.MainTemplate.product.fl_simple img.mainImg {
    padding: 48px;
    max-height: 810px;
}

.MainTemplate.fl_xl img.mainImg {
    width: 1630px;
    top: 0;
    left: 0;
}

.MainTemplate.ring img.mainImg,
.MainTemplate.led_961_577 img.mainImg {
    height: 540px;
    width: 540px;
    top: 0;
    left: 0;
}

.MainTemplate .flex-bakery {
    top: -120px;
    left: 30px;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    max-height: 145px;
    margin-top: 0;
    margin-bottom: 0;
}

.MainTemplate .flex-bakery .card {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    padding: 0;
}

.MainTemplate .flex-bakery .card p {
    font-size: 34px;
    text-align: center;
    color: black;
}

.MainTemplate .flex-bakery .card img {
    width: 70px;
    height: 70px;
}

.MainTemplate.ring .flex-bakery .card img,
.MainTemplate.led_961_577 .flex-bakery .card img {
    width: 35px;
    height: 35px;
}

.MainTemplate.portrait.caterer .img-ctr,
.MainTemplate.portrait_direct.caterer .img-ctr {
    padding: 205px 72px 0px 72px;
}
