.MFTemplate {
    font-size: 42px;
    position: relative;
}

@font-face {
    font-family: 'OpenSansExtrabold';
    src: url('/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('/fonts/OpenSans-Bold.ttf') format('truetype');
}

@keyframes MFOpacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes MFOrigin {
    0% {
        opacity: 0;
        transform: translate3d(50px, 0px, 0px);
    }

    20% {
        opacity: 0;
        transform: translate3d(50px, 0px, 0px);
    }

    25% {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }

    100% {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
    }
}

@keyframes MFPromo {
    0% {
        transform: scale3d(1, 1, 1);
    }

    20% {
        transform: scale3d(1, 1, 1);
    }

    25% {
        transform: scale3d(1.15, 1.15, 1.15);
    }

    30% {
        transform: scale3d(1, 1, 1);
    }

    35% {
        transform: scale3d(1.15, 1.15, 1.15);
    }

    40% {
        transform: scale3d(1, 1, 1);
    }

    45% {
        transform: scale3d(1.15, 1.15, 1.15);
    }

    50% {
        transform: scale3d(1, 1, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.MFTemplate.ligne_caisse {
    height: 812px;
    width: 1440px;
    display: flex;
    flex-direction: column;
}

.MFTemplate.led_3840_1056 {
    height: 1056px;
    width: 3840px;
    display: flex;
}

.MFTemplate .image {
    height: 100%;
    width: 1000px;
}

.MFTemplate .image img {
    height: 100%;
    margin: 0 auto;
    display: block;
}

.MFTemplate .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 50px;
    animation: MFOpacity 5s linear;
}

.MFTemplate.ligne_caisse .content {
    height: 406px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.MFTemplate.ligne_caisse .price {
    margin-left: 800px;
}

.MFTemplate.ligne_caisse .image {
    height: 406px;
    width: 10px;
    position: absolute;
}

.MFTemplate.ligne_caisse .promo {
    height: 406px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.Slide .MFTemplate .content {
    animation-iteration-count: infinite;
}

.MFTemplate .price {
    font-family: OpenSansExtrabold;
    display: flex;
    height: 100%;
    flex-direction: column;
    text-transform: uppercase;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 32px;
    box-sizing: border-box;
}

.MFTemplate .price .actualPrice {
    font-size: 140px;
    color: #f00;
    font-weight: bold;
    position: relative;
    line-height: 1;
    margin-bottom: 16px;
}

.MFTemplate.led_3840_1056 .price .actualPrice {
    font-size: 280px;
}

.MFTemplate .price .actualPrice .eur {
    display: block;
    position: absolute;
    top: -40px;
    right: -40px;
    font-size: 71px;
}

.MFTemplate.led_3840_1056 .price .actualPrice .eur {
    font-size: 140px;
    top: -80px;
    right: -80px;
}

.MFTemplate .price .quantity .qContainer {
    font-size: 71px;
    color: #f00;
    text-align: left;
    line-height: 1;
}

.MFTemplate.led_3840_1056 .price .quantity .qContainer {
    font-size: 140px;
}

.MFTemplate .price .quantity .or > p {
    font-size: 32px;
    color: #f00;
    font-family: OpenSansSemibold;
    text-align: left;
    text-transform: none;
    line-height: 1;
    margin-top: 14px;
}

.MFTemplate.led_3840_1056 .price .quantity .or > p {
    font-size: 64px;
    margin-top: 14px;
}

.MFTemplate .price .quantity .expirationDate {
    color: #000;
    text-align: left;
    font-size: 29px;
    font-family: OpenSansSemibold;
    text-transform: none;
    line-height: 1;
    margin-top: 12px;
}

.MFTemplate.led_3840_1056 .price .quantity .expirationDate {
    font-size: 58px;
    margin-top: 12px;
}

.MFTemplate .labels {
    position: absolute;
    display: flex;
    top: 0;
    right: auto;
    left: 0;
    align-items: center;
    bottom: auto;
    width: 100%;
}

.MFTemplate .labels > div {
    margin: 0 auto;
    padding-right: 300px;
    padding-top: 15px;
}

.MFTemplate .labels img {
    max-height: 200px;
    max-width: 200px;
    margin: 0 10px;
}

.MFTemplate.led_3840_1056 .labels img {
    max-width: 300px;
    max-height: 300px;
}

.MFTemplate .promo {
    flex-basis: 670px;
    width: 670px;
    min-width: 670px;
    background-color: #fdd500;
    background-image: url('/templates/mf-promo.png');
}

.MFTemplate.led_3840_1056 .promo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.MFTemplate .promo::after {
    content: 'PROMO';
    background-image: url('/templates/mf-promo-red.png');
    width: 670px;
    height: 100%;
    display: flex;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: OpenSansBold;
    font-size: 71px;
    animation: MFPromo 5s linear;
}

.Slide .MFTemplate .promo::after {
    animation-iteration-count: infinite;
}

.MFTemplate .data {
    display: flex;
    flex-direction: column;
    color: #676767;
    justify-content: flex-end;
    padding-bottom: 32px;
    line-height: 1;
    max-width: 750px;
}

.MFTemplate.led_3840_1056 .data {
    max-width: 1400px;
}

.MFTemplate .data h1 {
    font-family: OpenSansBold;
    color: #8bc34a;
    font-size: 77px;
    text-transform: uppercase;
    margin: 0;
    text-align: left;
}

.MFTemplate.led_3840_1056 .data h1 {
    font-size: 150px;
}

.MFTemplate .data .origin {
    display: flex;
    flex-direction: row;
    font-size: 42px;
    font-family: OpenSansBold;
    margin-top: 40px;
    animation: MFOrigin 5s linear;
    opacity: 0;
}

.MFTemplate.led_3840_1056 .data .origin {
    font-size: 84px;
}

.Slide .MFTemplate .data .origin {
    animation-iteration-count: infinite;
}

.MFTemplate .data .origin p {
    font-size: inherit;
    color: inherit;
    line-height: 1;
}

.MFTemplate .data .origin img {
    display: block;
    height: 75px;
    margin-right: 30px;
}

.MFTemplate .data .infos {
    display: flex;
    font-family: OpenSansBold;
    justify-content: flex-start;
    margin-top: 20px;
}

.MFTemplate.led_3840_1056 .data .infos {
    font-size: 84px;
}

.MFTemplate .data .infos p {
    font-size: inherit;
    font-family: inherit;
    columns: inherit;
    margin-right: 75px;
    line-height: 1;
}
