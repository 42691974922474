.Marronier .category {
    position: relative;
    margin-top: 45px;
}

.Marronier .category p {
    font-size: 18px;
    font-weight: 500;
}

.Marronier .row:first-child .category:first-child {
    margin-top: 0;
}

.Marronier .category .add {
    position: absolute;
    right: 0;
    top: 0;
}

.Marronier button.addCategory {
    position: absolute;
    top: 15px;
    right: 10px;
}

.Marronier.user .category {
    margin-left: 50px;
}

@media (max-width: 425px) {
    .Marronier.user .category {
        margin-left: 0;
    }

    .EditorModal .Marronier.user .category {
        margin: 0 25px;
        margin-top: 45px;
    }

    .EditorModal .Marronier.user {
        padding-top: 25px;
    }

    .EditorModal .AssetsBody .return {
        top: 0;
        left: 22px;
    }

    .EditorModal.AssetsPanelFolders.modal-body {
        min-height: 25px;
    }
}
