.editor-slides {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.editor-slides .slide {
    margin: 0 12px;
}

.editor-slides .SlideContainer {
    overflow: visible;
    position: relative;
}

.editor-slides .SlideContainer .Slide {
    cursor: pointer;
}

.editor-slides .delete,
.editor-slides .duplicate {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #596b8c;
    height: 24px;
    width: 24px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    font-size: 12px;
    z-index: 2;
    user-select: none;
}

.editor-slides .duplicate {
    top: 24px;
}

.editor-slides .img-preview-container,
.editor-slides .preview-asset-container {
    height: 100%;
    width: 100%;
    position: relative;
    outline: 1px solid #aaa;
    background-color: #ffffff;
    transition: background-color 0.2s ease-in-out;
}

.custom-modal.Assistant .img-preview-container {
    max-width: 315px;
    margin: 0 auto;
}

.editor-slides .img-preview-container.invalid {
    background-color: #fff2e8;
}

.editor-slides .img-preview-container.invalid:hover {
    background-color: #fecea7;
}

.img-preview-container .warning,
.preview-asset-container .warning {
    display: none;
}

.editor-slides .img-preview-container.invalid .warning,
.editor-slides .preview-asset-container .warning {
    display: flex;
    position: absolute;
    background-color: #fff;
    border: 1px solid #00000033;
    border-bottom: none;
    border-right: none;
    height: 25px;
    width: 25px;
    bottom: 0px;
    right: 0px;
    color: #FD8200;
    font-weight: 900;
    justify-content: center;
    align-items: center;
}

.editor-slides .img-preview-container.invalid:hover .warning,
.editor-slides .preview-asset-container:hover .warning {
    opacity: 1;
}

.editor-slides .img-preview-container.invalid:hover .warning:hover p,
.editor-slides .preview-asset-container:hover .warning:hover p {
    display: block;
}

.editor-slides .img-preview-container.invalid .warning p,
.editor-slides .preview-asset-container .warning p {
    display: none;
    position: absolute;
    bottom: -82px;
    width: 300px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #b6b6b6;
    color: #596b8c;
    line-height: 1.6;
    padding: 10px 0 8px 0;
    z-index: 9;
}
