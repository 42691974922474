.addSlide {
    height: 320px;
    width: 180px;
    border: 2px dashed #777;
    position: relative;
    border-radius: 8px;
    transition: background-color 0.4s;
    cursor: pointer;
}

.addSlide.landscape,
.addSlide.led_1824_1056,
.addSlide.fl_simple,
.addSlide.fl_1680_946,
.addSlide.ring,
.addSlide.led_961_577 {
    width: 320px;
    height: 180px;
}

.addSlide.led_962_578 {
    width: 320px;
    height: 192px;
}

.addSlide.led_4032_288 {
    width: 280px;
    height: 20px;
}

.addSlide.fl_xl {
    width: 280px;
    height: 315px;
}

.addSlide.bandeau {
    width: 320px;
    height: 36px;
}

.addSlide.ligne_caisse {
    width: 640px;
    height: 90px;
}

.addSlide.led_792_192 {
    width: 320px;
    height: 77.8707224335px;
}

.addSlide.led_288_420 {
    width: 180px;
    height: 262.5px;
}

.addSlide.led_288_384 {
    width: 180px;
    height: 221.645021645px;
}

.addSlide.led_462_320 {
    width: 320px;
    height: 240px;
}

.addSlide.led_448_340 {
    width: 224px;
    height: 170px;
}

.addSlide.led_3840_1056 {
    width: 320px;
    height: 89px;
}

.labelCol.active {
    box-shadow: 0px 0px 0px 2px #5893df;
}

.LabelsHorizontalScroll {
    display: flex;
    flex-wrap: wrap;
}

.LabelsHorizontalScroll > .labelCol {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    min-width: 100px;
    max-height: 150px;
    min-height: 120px;
    cursor: pointer;
    position: relative;
}

.addSlide:hover {
    background-color: #3332;
}

.addSlide i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.previewAsset {
    width: auto;
    outline: 1px solid #aaa;
    max-height: 340px;
    margin: auto auto;
    display: block;
}

.Slide.orportrait video.previewAsset.video {
    height: 320px;
}

.Slide.orlandscape video.previewAsset.video {
    height: 180px;
}

img.previewAsset {
    max-height: 100%;
    max-width: 100%;
}

.SlideContainer {
    margin-bottom: 1em;
    overflow: hidden;
}

.Slide {
    height: 320px;
    width: 180px;
}

.Slide.future {
    opacity: 0.6;
}

.f-12 {
    font-size: 12px;
}

.Slide.orlandscape,
.Slide.orled_1824_1056,
.Slide.orfl_simple,
.Slide.orfl_1680_946,
.Slide.orring,
.Slide.orled_961_577 {
    width: 320px;
    height: 180px;
}

.Slide.orled_962_578 {
    width: 320px;
    height: auto;
}

.Slide.orfl_xl {
    width: 280px;
    height: 315px;
}

.Slide.orbandeau {
    width: 320px;
    height: 36px;
}

.Slide.orligne_caisse {
    width: 640px;
    height: 90px;
}

.Slide.orled_961_577 {
    height: 200px;
}

.Slide.orled_960_576,
.addSlide.led_960_576 {
    width: 266px;
    height: 160px;
}

.Slide.orled_336_25,
.Slide.orled_768_204,
.Slide.orled_576_153,
.Slide.orled_3840_1056 {
    width: 320px;
}

.Slide.orled_448_340 {
    width: 224px;
    height: 170px;
}

.Slide.orlandscape.adm,
.Slide.orfl_1680_946.adm,
.Slide.orfl_simple.adm,
.Slide.orring.adm {
    width: 180px;
    height: auto;
}

.Slide.orled_792_192 {
    width: 320px;
    height: 77.8707224335px;
}

.Slide.orled_288_420 {
    width: 180px;
    height: 262.5px;
}

.Slide.orled_288_384 {
    width: 180px;
    height: 221.645021645px;
}

.Slide.orled_462_320 {
    width: 320px;
    height: 240px;
}

.SlideBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    color: #777f8c;
    line-height: 1.33;
    padding: 0 8px;
    margin-top: 4px;
}

.SlideBottom i {
    font-size: 24px;
    cursor: pointer;
}

.SlideBottom p {
    margin: 0;
    text-decoration: underline;
}

.SlideBottom p > i {
    font-size: 11px;
    margin-right: 4px;
}

.SlideBottom .right {
    display: inline-block;
    width: 32%;
    vertical-align: top;
}

.handlenumber {
    width: 57px !important;
}

.pricenumber {
    display: flex;
}

.priceunit {
    width: 110px;
    margin-right: 8px;
    margin-left: 17px;
}

@media (max-width: 425px) {
    .priceunit {
        width: 100px;
    }

    .Slide.orligne_caisse,
    .addSlide.ligne_caisse {
        width: 275px;
        height: 38px;
    }
}

.firstblock {
    display: block;
}

.secondblock {
    display: none !important;
}

.switchPromo {
    vertical-align: top;
    margin-right: 1em;
}

.noLabel {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    font-size: 28px;
}

.editorFruitFooter {
    text-align: initial;
}

.controls {
    margin-top: 1em;
}

.controls img.fruitSelection {
    height: 100px;
    border: 2px solid transparent;
}

.controls img.fruitSelection.active {
    border-color: #aaa;
    height: 100px;
}

.returnBtn {
    margin-right: 1em;
}

.labelImg {
    width: 100%;
    max-width: 100px;
    margin: 0 auto;
    display: block;
}

.nomSequence {
    margin: -10px;
    margin-bottom: 24px;
    margin-top: -25px;
    width: calc(100% + 20px);
    height: 50px;
    background-color: #eee;
    display: flex;
    align-items: center;
}

.nomSequence .name {
    width: 66.6666667%;
    display: flex;
    align-items: center;
}

.flex {
    display: flex;
}

.flex.center {
    align-items: center;
    margin: 0;
}

.nomSequence .name > * {
    vertical-align: middle;
}

.nomSequence .seqName {
    display: inline-block;
    margin: 0;
    line-height: 50px;
    padding-left: 25px;
}

.nomSequence .buttons {
    display: inline-block;
    padding-right: 25px;
    width: 33.3333333%;
}

.nomSequence .name select {
    margin-left: 1em;
    margin-right: 1em;
    width: 150px;
}

.nomSequence .buttons .right {
    text-align: right;
    width: 60%;
    display: inline-block;
}

.nomSequence .fifty {
    width: 40%;
    display: inline-block;
    padding-left: 25px;
}

.nomSequence .name input {
    display: inline-block;
    width: 200px;
    margin-right: 1em;
    margin-left: 1em;
}

.nomSequence span {
    display: inline-block;
}

/* .ScreensSelection {
} */

input.form-control.error {
    border-color: rgb(235, 82, 82);
}

input.form-control.error:focus {
    border-color: rgb(180, 63, 63);
}

span.errorLabel {
    display: block;
    text-align: center;
    font-size: 10px;
    color: rgb(235, 82, 82);
}

.modal30 {
    width: 30%;
}

.rSelectError > div:first-of-type,
.rSelectError > div:hover:first-of-type {
    border-color: rgb(235, 82, 82);
    box-shadow: 0 0 0 1px rgb(235, 82, 82);
}

.mr-1 {
    margin-right: 1em;
}

.FruitSaveButton {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.img-responsive {
    outline: 1px solid transparent;
    outline-offset: 5px;
}

.AssetListVideoPLays {
    max-height: 160px;
    min-height: 160px;
    max-width: 90px;
}

.AssetListContainer.olandscape .AssetListVideoPLays,
.AssetListContainer.oring .AssetListVideoPLays {
    max-width: 160px;
    min-height: 0;
}

.AssetListContainer.olandscape .selected.vid,
.AssetListContainer.oring .selected.vid {
    top: 0;
    transform: translate(-50%, 0);
}

.AssetListContainer {
    height: 230px;
}

.AssetListContainer .oImgCtr img {
    height: 160px;
}

.AssetListContainer.olandscape .oImgCtr,
.AssetListContainer.ofl_1680_946 .oImgCtr,
.AssetListContainer.oring .oImgCtr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    position: relative;
}

.AssetListContainer.olandscape .oImgCtr img,
.AssetListContainer.ofl_1680_946 .oImgCtr img,
.AssetListContainer.oring .oImgCtr img,
.AssetListContainer.oled_961_577 .oImgCtr img {
    width: 160px;
    height: 90px;
}

.AssetListContainer.obandeau,
.AssetListContainer.oligne_caisse .oImgCtr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    position: relative;
}

.AssetListContainer.obandeau .oImgCtr img {
    width: 160px;
    height: 25px;
}

.AssetListContainer.oligne_caisse .oImgCtr img {
    width: 160px;
    height: 35px;
}

.modal-header h4.black {
    color: black;
}

.fa.ip5 {
    display: none;
}

.searchBox.editor {
    top: -15px;
    top: -20px;
    display: inline-block;
    position: relative;
}

.formodal {
    display: flex;
}

.myeditor {
    margin-top: 13px;
    margin-left: 20px;
    left: 5px;
    top: -20px;
    display: inline-block;
    position: relative;
}

.handlemodalheader {
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

.searchBox.editor .assetSearchBar {
    margin: 0;
    width: 140px;
}

.AssetListContainer .selected,
.AssetsPanelFolders .selected {
    position: absolute;
    top: 0;
    width: 90px;
    height: 160px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    animation-name: fadeInCheck;
    animation-duration: 0.2s;
    color: #fff;
    line-height: 160px;
}

.AssetListContainer.olandscape .selected,
.AssetListContainer.oring .selected {
    width: 100%;
    width: 160px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 90px;
    line-height: 90px;
    position: absolute;
}

.AssetsPanelFolders .breadcrumbs {
    color: #0070e0;
}

span.addExpDate {
    font-size: 10px;
    line-height: 1;
    height: auto;
    cursor: pointer;
}

@keyframes fadeInCheck {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.6);
    }
}

@keyframes fadeInCheckMob {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

@media (max-width: 1024px) {
    .nomSequence .buttons {
        padding-right: 5px;
    }

    .nomSequence .fifty {
        width: 35%;
    }

    .nomSequence .buttons .right {
        width: 65%;
    }

    .labelImg {
        width: 100%;
        max-width: 54px;
        margin: 0 auto;
        display: block;
    }

    .noLabel {
        font-size: 14px;
    }

    .Slide.orled_962_578 {
        width: auto;
    }
}

@media (min-width: 1200px) {
    .col-lg-3 {
        width: auto;
    }
}

@media (min-width: 1200px) {
    .col-lg-4 {
        width: auto;
    }
}

@media (max-width: 580px) {
    .myhr {
        position: initial !important;
    }
    .affiches {
        display: contents;
    }
    .allmodal {
        display: inline-block;
    }
    .myrayontrad {
        margin-left: 0px !important;
    }
    .rayons {
        margin-left: -23px;
    }
    .alltrad {
        margin-left: 56px;
    }
    .modalsequence {
        margin-top: 45px !important;
    }
    .modal-content {
        min-height: 93vh;
    }
    .logoaffiche {
        display: block !important;
    }
}

.PreviewFruit {
    text-align: center;
    height: 320px;
    position: relative;
}

.CenterFruit {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 1px;
}

.PreviewFruitContainer {
    transform: scale(0.17061611374);
    transform-origin: top left;
    width: max-content;
    border: 3px solid black;
}

.oring .PreviewFruitContainer,
.oled_961_577 .PreviewFruitContainer {
    transform: scale(0.3333333333);
}

.ofl_simple .PreviewFruitContainer {
    transform: scale(0.219);
}

.ofl_1680_946 .PreviewFruitContainer {
    transform: scale(0.168);
}

.ofl_xl .PreviewFruitContainer {
    transform: scale(0.1699999);
}

.oled_288_416 .PreviewFruitContainer {
    transform: scale(0.7);
}

.oled_192_272 .PreviewFruitContainer {
    transform: scale(0.9);
}

.oled_448_340 .PreviewFruitContainer {
    transform: scale(0.6);
}

.oled_336_255 .PreviewFruitContainer {
    transform: scale(0.6);
}

.oled_768_204 .PreviewFruitContainer {
    transform: scale(0.4);
}

.oled_576_153 .PreviewFruitContainer {
    transform: scale(0.5);
}

.oligne_caisse .PreviewFruitContainer {
    transform: scale(0.11);
}

.oled_3840_1056 .PreviewFruitContainer {
    transform: scale(0.083);
}

.Slide .oligne_caisse .PreviewFruitContainer {
    transform: scale(0.22);
    transform-origin: top left;
}

.Slide .oled_3840_1056 .PreviewFruitContainer {
    transform: scale(0.083);
    transform-origin: top left;
}

.Slide .CenterFruit.oligne_caisse {
    width: 320px;
}

.Slide .CenterFruit.oligne_caisse {
    width: 640px;
}

@media (max-width: 425px) {
    .PreviewFruitContainer {
        border: 8px solid black;
    }

    .Slide .oligne_caisse .PreviewFruitContainer {
        transform: scale(0.094);
        transform-origin: top left;
    }

    .Slide .CenterFruit.oligne_caisse {
        width: 275px;
    }
}

.CenterFruit.olandscape,
.CenterFruit.ofl_simple,
.CenterFruit.oled_1824_1056,
.CenterFruit.ofl_1680_946,
.CenterFruit.oring,
.CenterFruit.oled_961_577,
.CenterFruit.oligne_caisse,
.CenterFruit.oled_3840_1056 {
    width: 320px;
}

.CenterFruit.oled_448_340 {
    width: 273px;
}

.CenterFruit.oled_768_204 {
    width: 310px;
}

.CenterFruit.oled_576_153 {
    width: 291px;
}

.CenterFruit.ofl_xl {
    width: 280px;
}

.adm .CenterFruit.oring,
.adm .CenterFruit.olandscape,
.adm .CenterFruit.ofl_simple,
.adm .CenterFruit.ofl_1680_946,
.adm .CenterFruit.oled_961_577 {
    width: 160px;
}

.adm .olandscape .PreviewFruitContainer {
    transform: scale(0.083333);
}

.adm .oring .PreviewFruitContainer,
.adm .oled_961_577 .PreviewFruitContainer {
    transform: scale(0.166666);
}

.adm .ofl_simple .PreviewFruitContainer {
    transform: scale(0.119);
}

.adm .ofl_1680_946 .PreviewFruitContainer {
    transform: scale(0.119);
}

.adm.orring .PreviewFruit {
    height: 108px;
}

.Slide.orlandscape .PreviewFruit,
.Slide.orled_1824_1056 .PreviewFruit,
.Slide.orfl_simple .PreviewFruit,
.Slide.orfl_1680_946 .PreviewFruit,
.Slide.orring .PreviewFruit,
.Slide.orrled_961_577 .PreviewFruit {
    height: 180px;
}

.Slide.orled_3840_1056 {
    height: 89px;
}

.Slide.orled_4032_288 {
    width: 280px;
    height: 20px;
}

.Slide.orfl_xl .PreviewFruit {
    width: 280px;
    height: 315px;
}

.Slide.orring img.previewAsset {
    max-height: 100%;
}

.logoaffiche img {
    width: 45%;
}

.affiches {
    height: auto;
}

.modal-body {
    /* overflow-y: scroll; */
    min-height: 25vh;
    /* max-height: calc(80vh - 30px); */
}

.flexfolder .nb {
    width: 18px;
}

.chooseaffiche {
    text-align: center;
    margin-bottom: 20px;
}

/* .colnumber {
    width: 42% !important;
} */

/* .xsnumber {
    display: initial !important;
} */

.inputflex {
    display: flex;
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.inputtoflex {
    display: flex !important;
}

.formpoids {
    width: 64px !important;
}

.supportSelect {
    margin-bottom: 20px;
}

.hidinput {
    display: none;
}

.activescreens {
    margin-bottom: 10px;
}

.fromagie {
    height: 39px;
}

@media (max-width: 356px) {
    .btn.btn-danger {
        margin-left: 0;
        margin-top: 3px;
    }

    .btn.btn-info {
        margin-top: 3px;
    }
}

@media (max-width: 992px) {
    .logoaffiche img {
        width: 52px;
    }
}

@media (max-width: 1024px) {
    .SlideBottom i {
        font-size: 15px;
    }

    .LabelsHorizontalScroll > .labelCol {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: center;
        max-width: 75px;
        min-width: 50px;
        max-height: 75px;
        min-height: 60px;
        cursor: pointer;
        position: relative;
    }

    .nomSequence {
        height: 100px;
        position: fixed;
        width: calc(100% - 48px);
        z-index: 9;
        border-top: 8px solid #f7f9fa;
        top: 22px;
    }

    .btn.btn-default.returnBtn {
        display: none;
    }

    .nomSequence .seqName {
        line-height: 1;
        margin-bottom: 10px;
    }

    .nomSequence .name {
        width: 100%;
        text-align: center;
        flex-direction: column;
    }

    .firstblock {
        display: none !important;
    }

    .secondblock {
        display: block !important;
    }

    .nomSequence .seqName {
        padding-left: 0;
        font-size: 16px;
    }

    .nomSequence .name input {
        width: 100px;
    }

    .nomSequence .buttons {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .nomSequence .buttons button {
        display: inline-block;
    }

    .nomSequence .fifty {
        width: auto;
        display: inline-block;
        padding-left: 0;
        text-align: left;
    }

    .nomSequence .buttons .right {
        width: auto;
    }

    .Creas {
        margin-top: 100px;
    }

    .SlideContainer {
        margin-bottom: 1em;
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .Slide {
        height: 160px;
        width: 90px;
        margin-left: auto;
        margin-right: auto;
    }

    .PreviewFruit {
        height: 160px;
    }

    .PreviewFruitContainer {
        transform: scale(0.08333333333);
    }

    .oring .PreviewFruitContainer {
        transform: scale(0.16666666666);
    }

    .ofl_simple .PreviewFruitContainer {
        transform: scale(0.109);
    }

    .ofl_1680_946 .PreviewFruitContainer {
        transform: scale(0.109);
    }

    .ofl_xl .PreviewFruitContainer {
        transform: scale(0.114);
    }

    .Slide.orfl_xl {
        height: 230px;
    }

    .Slide.orlandscape,
    .Slide.orfl_1680_946,
    .Slide.orfl_simple,
    .Slide.orring {
        width: 160px;
        height: 90px;
    }

    .CenterFruit {
        width: 90px;
    }

    .CenterFruit.olandscape,
    .CenterFruit.ofl_1680_946,
    .CenterFruit.ofl_simple,
    .CenterFruit.oring {
        width: 160px;
    }

    .CenterFruit.ofl_xl {
        width: 200px;
    }

    .SlideBottom {
        width: 100%;
    }

    .SlideBottom .left {
        font-size: 9px;
    }

    .SlideBottom i {
        padding: 2px;
    }

    .addSlide {
        height: 160px;
        width: 90px;
        margin-left: auto;
        margin-right: auto;
    }

    .addSlide.ring,
    .addSlide.fl_simple,
    .addSlide.fl_1680_946,
    .addSlide.landscape,
    .addSlide.led_1824_1056 {
        width: 160px;
        height: 90px;
        margin-left: auto;
        margin-right: auto;
    }

    .addSlide.fl_xl {
        height: 230px;
        width: 190px;
        margin-left: auto;
        margin-right: auto;
    }

    .ScreensSelection {
        margin-top: 2em;
        padding-top: 2em;
        border-top: 1px solid #aaa;
    }

    .ScreensSelection > h4 {
        margin-top: 0;
    }

    .modal-body.AffModal {
        padding: 0 15px;
    }

    .labelCol {
        max-height: 100px;
        margin-bottom: 1em;
    }

    .labelCol .labelImg {
        max-height: 100px;
        width: auto;
        max-width: 100%;
    }

    .groupBox {
        margin: 0;
        margin-bottom: 1em;
    }

    .btn-asset-remove .btn.btn-info {
        margin-right: 0;
        margin-bottom: 1em;
    }

    .modal30 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .previewAsset {
        max-height: 160px;
        width: auto;
    }

    .EditorModal {
        max-height: 60vh;
        overflow-y: scroll;
    }

    .EditorModalValidate {
        float: right;
    }

    .searchBox.editor {
        display: inline-block;
        position: relative;
        margin: auto;
        width: 100%;
    }

    .searchBox.editor input {
        width: 100%;
    }

    span.addExpDate {
        font-size: 9px;
    }

    .AssetListContainer .selected,
    .AssetsPanelFolders .selected {
        background-color: rgba(0, 0, 0, 0.3);
        animation-name: fadeInCheckMob;
    }
}

@media (max-width: 320px) {
    .nomSequence .seqName {
        padding-left: 0;
        font-size: 14px;
    }

    .nomSequence .name input {
        width: 80px;
    }

    .nomSequence .buttons span {
        font-size: 9px;
        margin-right: 5px;
    }

    .controls > div {
        overflow: auto;
        white-space: nowrap;
    }

    .controls img.fruitSelection {
        display: inline-block;
        text-align-last: center;
    }

    .fa.ip5 {
        display: block;
        float: right;
    }
}

@media (min-width: 1027px) and (max-width: 1200px) {
    .nomSequence {
        height: 80px;
    }

    .nomSequence .name {
        height: 80px;
        vertical-align: top;
    }

    .nomSequence .seqName {
        line-height: 80px;
    }
}

@keyframes editBlink {
    0% {
        border-color: #5893df;
        background-color: #fff;
        color: #5893df;
    }

    50% {
        background-color: #df8458;
        border-color: #df8458;
        color: white;
    }

    100% {
        border-color: #5893df;
        background-color: #fff;
        color: #5893df;
    }
}

.btn-info.btnSaveEditor.blink {
    animation: editBlink 2s infinite;
}

.screenShotsSlider {
    display: flex;
}

.screenShotsSlider img {
    width: 100%;
}

.screenShotsSlider img.imgPort {
    transform: rotate(-90deg);
    margin-top: calc((320px - 180px) / 2);
    margin-left: -70px;
    width: 320px;
    height: 180px;
}

.labelPromo.selectable {
    display: inline-block;
    vertical-align: top;
}

.labelPromo.selectable.selected {
    border: 2px solid #eee;
}

.labelPromo.selectable > img {
    height: 100px;
}

.myhr {
    border-left: 1px solid grey;
    height: 100%;
    position: absolute;
    left: 50%;
}

.logoaffiche .btn-default {
    min-width: 138px;
    height: 30px;
    margin: 4px;
}

.logoaffiche {
    display: flex;
}

.asset-options .affiches {
    text-align: left !important;
}
.btn_desa_clone {
    margin-left: 0px !important;
}

.folder .btn-default {
    min-width: 138px;
    margin-bottom: 12px;
    margin-right: -33px;
    margin-top: -3px;
}

.flexfolder {
    display: inline-flex;
}

.fa-building {
    margin-left: 35px;
    font-size: 24px;
    color: green;
}

.fa-file-alt {
    margin-left: 37px;
    font-size: 21px;
    color: rgb(0, 162, 255);
}

.fa-calendar-alt {
    margin-left: 37px;
    font-size: 21px;
    color: rgb(0, 162, 255);
}

.fa-calendar-day {
    margin-left: 36px;
    font-size: 21px;
    color: lightblue;
}

.folderall {
    margin-top: -40px;
}

.firstfold {
    margin-top: 41px;
}

.modalsequence {
    margin-top: 30px;
    margin-left: 60px;
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: 30px auto;
        /* height: calc(100vh - 20px); */
        min-width: 40vw;
        /* overflow-y: scroll; */
    }
    .margfilter {
        margin: 15px;
    }
    .removehr {
        display: none;
    }
}

@media (max-width: 768px) {
    .pull-right {
        width: fit-content;
        float: none !important;
        margin-left: 40px;
    }

    div.pull-left {
        float: none !important;
    }

    .SlideBottom {
        padding: 0;
    }

    .SlideBottom p {
        font-size: 9px;
        text-align: left;
    }

    .SlideBottom i {
        display: none;
    }
}

.full_info {
    margin-top: 100px;
}

.square {
    width: 25%;
    height: 10%;
    border: 1px solid black;
    padding: 10px;
}

.hrmarg {
    margin-bottom: 5%;
}

.moveselect {
    display: flex;
    justify-content: center;
}

.moveselect h3 {
    font-size: 20px;
}
.galerie {
    height: 29px !important;
    font-size: 17px !important;
    width: 18vw;
    margin-top: 30px;
}

.newsassets {
    height: 29px !important;
    font-size: 14px !important;
}

.handlelabel {
    padding: 2px;
    width: 25vw;
    margin: 10px;
    font-size: 20px;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.checkofcamp {
    margin-left: 10px;
}

.itemchamp {
    display: contents;
    font-size: 15px;
}

.marginsimple {
    margin: 10px;
}

.selectcamp {
    max-height: 60vh;
    overflow: auto;
    margin-top: 30px;
    max-width: 40vw;
}

.campagne {
    width: 200px !important;
}
.btncamp {
    margin: auto;
    display: block;
    height: 40px;
    width: 130px;
    margin-top: 40px;
    font-size: 15px;
}

.selectmultip label {
    margin-right: 8px;
}

.checkedcp {
    width: 20px;
    height: 40px;
}

.adjustcommand {
    margin-left: 10px;
}

.pull-left {
    float: right !important;
}

.selectcheckb {
    display: flex;
    margin-top: 19px;
}

.selectcheckb input {
    margin-left: 5px;
}

.ecran_selected {
    margin-left: 5px;
}

.movescreen {
    display: flex;
}

.modal-header .blueTitle {
    color: #0070e0;
    display: inline-block;
}
