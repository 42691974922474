.CampaignsEditor .panel-default > .panel-heading {
    background-color: transparent;
}

.ModalPlanning .slices {
    text-align: center;
    margin-top: 1em;
}

.ModalPlanning .slices .block {
    display: inline-block;
    border: 1px solid #e5e9ec;
    padding: 10px 20px;
    cursor: pointer;
    border-left: none;
    margin-bottom: 1.5em;
    user-select: none;
}

.ModalPlanning .slices .block.selected {
    background-color: #63cb89;
    color: white;
    border-color: #63cb89;
}

.ModalPlanning .slices .block:first-child {
    border-left: 1px solid #e5e9ec;
}

.ModalPlanning .timing {
    text-align: center;
    margin-top: 1em;
}

.ModalPlanning .timing .block {
    display: inline-block;
    border: 1px solid #e5e9ec;
    height: 25px;
    width: 25px;
    line-height: 25px;
    cursor: pointer;
    border-left: none;
    user-select: none;
}

.ModalPlanning .timing .block:last-child {
    background-color: #eee;
}

.ModalPlanning .timing .block.selected {
    background-color: #63cb89;
    color: white;
    border-color: #63cb89;
}

.ModalPlanning .timing .block:first-child {
    border-left: 1px solid #e5e9ec;
}

.Planning span.timeRange {
    margin: 0 2.5px;
    display: inline-block;
}

.Planning table.fixed {
    table-layout: fixed;
}

.Planning .addTimeModal {
    transition: background-color 0.4s;
    cursor: pointer;
}

.Planning .addTimeModal:hover {
    background-color: #e5e9ec;
}

.AddCreative {
    height: 160px;
    width: 90px;
    display: inline-block;
    border: 2px dashed #777;
    border-radius: 8px;
    transition: background-color 0.4s;
    cursor: pointer;
    vertical-align: top;
    margin-right: 1em;
}

.AddCreative:hover {
    background-color: #3332;
}

.AddCreative i {
    line-height: 160px;
    text-align: center;
    width: 100%;
}

.Creatives .rotate {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.Creative {
    display: inline-block;
    margin-right: 1em;
}

.Creative video {
    max-width: 100%;
    height: 160px;
    width: 90px;
    vertical-align: top;
}

.Creative .controls {
    text-align: center;
}

.ml-1 {
    margin-left: 1em;
}

.CampaignErrors {
    background-color: #ffbaba;
    border: 1px solid #cc0000;
    color: #cc0000;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 1em;
}

.CampaignErrors ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mt-1 {
    margin-top: 1em;
}

.modalManualSelect {
    max-height: 70vh;
    overflow-y: scroll;
}

.modalManualSelect span {
    vertical-align: top;
    line-height: 25px;
}

.modalManualSelect ul {
    list-style-type: none;
    padding: 0;
}

.switchText {
    display: inline-block;
    line-height: 20px;
    vertical-align: top;
    margin-left: 10px;
}

.switchContainer {
    display: inline;
}


.controlsScreen span {
    font-size: 9px;
    vertical-align: top;
}

.panel-default {
    overflow-x: scroll;
    padding: 25px;
}


.controlsScreen {
    height: 10px;
}

.controlsScreen span:last-child {
    float: right;
    vertical-align: top;
}

.saveall {
    margin: 12px;
}

.prog-camp.form-group {
    display: flex;
    flex-basis: 0;
    flex-direction: column;
}

.prog-camp.form-group .prog-camp-box {
    display: flex;
    align-items: center;
}

.prog-camp.form-group input {
    width: 15px;
    max-width: 15px;
    min-width: 15px;
    margin: 0;
}

.prog-camp.form-group label {
    margin: 0;
    text-align: left;
    margin-left: 10px;
}
