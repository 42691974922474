.title {
    margin-top: 10px;
    color: grey;
}

.bottom-btn {
    margin-top: 10px;
    width: 100%;
    height: 38px;
}

.container {
    height: 200vh;
    position: 'relative';
}
