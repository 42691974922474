.AdminAssetList {
    margin-top: 1em;
}

.panel-default > .panel-heading.AdminAssetsHeader {
    margin: -25px -25px 0 -25px;
    padding: 10px 15px;
    background-color: #eee;
}

.AdminAssetsHeader h5 {
    display: inline-block;
}

.AdminAsset {
    text-align: center;
}

.AdminAssetPreview {
    display: block;
    max-width: 100%;
}

.AdminAssetLoad {
    height: 100px;
    position: relative;
}

.AdminAssetLoad .Aloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
}

.AdminAsset > img {
    border: 1px solid #eee;
}

.AdminAsset.olandscape,
.AdminAsset.oligne_caisse,
.AdminAsset.ofl_1680_946,
.AdminAsset.ofl_simple,
.AdminAsset.oring,
.AdminAsset.oled_961_577 {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminAsset.oled_792_192 {
    height: 77.8707224335px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminAsset.oled_288_420 {
    height: 262.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminAsset.oled_288_384 {
    height: 221.645021645px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminAsset.oled_462_320 {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminAsset.oled_792_192 > img {
    width: 180px;
    height: 50px;
}

.AdminAsset.oled_288_420 > img {
    width: 125px;
}

.AdminAsset.oled_288_384 > img {
    width: 120px;
}

.AdminAsset.oled_462_320 > img {
    width: 150px;
    height: 100px;
}

.AdminAsset.olandscape > img,
.AdminAsset.oligne_caisse > img,
.AdminAsset.ofl_1680_946 > img,
.AdminAsset.ofl_simple > img,
.AdminAsset.oring > img,
.AdminAsset.oled_961_577 > img {
    height: fit-content;
}

.AdminAsset.obandeau > img {
    height: fit-content;
}

.animated.brightness {
    filter: brightness(1);
    transition: filter 0.2s ease-in-out;
    cursor: pointer;
}

.animated.brightness:hover {
    filter: brightness(1.2);
}

.AdminCreativeStaticText .defaultCheck {
    color: #63cb89;
}

/* Tags */
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    border-color: #a5d24a;
}

.react-tagsinput-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: ' ×';
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}

/* End */

.AdminCreativeStaticText {
    position: relative;
    display: block;
    text-align: center;
    height: 130px;
}

.AdminCreativeStaticText .tag,
.AssetListContainer .tag {
    background-color: #7b1712;
    margin: 2px;
    padding: 0 4px;
    border-radius: 4px;
    display: inline-block;
    color: #eee;
}

.AdminCreativeStaticText .tag.sign {
    background-color: rgb(20, 63, 112);
}

.AdminCreativeStaticText .tag.group {
    background-color: rgb(22, 107, 29);
}

input.assetSearchBar {
    display: inline-block;
    width: auto;
    vertical-align: top;
    margin-right: 2em;
    padding-left: 2.5em;
}

.AdminAssetsHeader .searchBox {
    width: auto;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.searchBox i {
    position: absolute;
    left: 1em;
    line-height: 32px;
    color: #777;
}

.AdminAsset.oldAssets {
    height: 160px;
}
