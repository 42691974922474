.foremail {
    width: 80% !important;
}
.emailcredentials {
    display: flex;
    width: auto;
}

.mycheckbox {
    width: 20px;
    height: 20px;
    margin-left: 10px !important;
}

.emailchecked {
    display: flex;
}

.btn_email {
    display: flex;
}

.mycontainer {
    margin: 5px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
.mycontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 23px;
    background-color: #eee;
}
  
.mycontainer:hover input ~ .checkmark {
    background-color: #ccc;
}
  
.mycontainer input:checked ~ .checkmark {
    background-color: #2196F3;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.mycontainer input:checked ~ .checkmark:after {
    display: block;
}
  
.mycontainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.widthgalerie {
    width: 100%;
}

.gal_2 {
    margin: 20px;
}

.gal_2 p {
    font-size: 17px
}


.Gallery header {
    position: relative;
}

.Gallery img.logo {
    width: 200px;
    position: absolute;
    top: 5px;
    right: 12px;
}

.Gallery .infos {
    padding: 10px 0;
}

.Gallery .infos img {
    display: inline-block;
}

.Gallery .infos .text {
    display: inline-block;
    vertical-align: top;
    margin-left: 2.5em;
}

.Gallery .infos .text p {
    margin-top: 0;
}

.Gallery .filters {
    margin-left: auto;
    width: 100%;
}

.Gallery .group {
    position: relative;
    margin: 0 -10px;
}

.Gallery .group .image {
    width: 31.48289%;
    overflow: hidden;
    height: 250px;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    margin-top: 1em;
}

.Gallery .group .image::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255);
    opacity: 0;
    transition: opacity 0.4s;
}

.Gallery .group .image:hover::after {
    opacity: 0.2;
}

.Gallery .group .image img,
.Gallery .group .image video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    min-height: 100%;
    z-index: -99;
}

.Gallery .image .overlay {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
    width: 100%;
    padding: 4px 0;
}

.Gallery .image .overlay p {
    margin: 0;
    color: white;
    padding: 0 15px;
    font-size: 10px;
    line-height: 16px;
}

hr {
    opacity: 0.4;
}

span.filterTag {
    color: rgba(0, 0, 0, 0.4);
}

/* Slideshow */
.Slideshow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
}

.Slideshow .window {
    height: calc(100% - 40px);
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Slideshow .window .content {
    position: relative;
    background-color: #fff;
    text-align: center;
}

.Slideshow .window img,
.Slideshow .window video {
    max-height: 80%;
    max-width: 100%;
    vertical-align: top;
    user-select: none;
    z-index: -99;
}

.Slideshow .window .infos {
    height: calc(20% - 20px);
    padding: 10px;
    text-align: left;
    position: relative;
}

.Slideshow .window .text {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-left: 0;
    height: 100%;
}

.Slideshow .window .map {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    height: 100%;
}

.Slideshow .window .infos p {
    margin: 0;
}

.Slideshow .infos h2 {
    margin: 0;
}

.controls .next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 60px;
    line-height: 80px;
    text-align: center;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: background-color 0.4s;
}

.controls .prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 60px;
    line-height: 80px;
    text-align: center;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: background-color 0.4s;
}

.controls .next:hover,
.controls .prev:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.controls .close {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: background-color 0.4s;
}

.controls .close:hover {
    background-color: rgba(255, 113, 113, 0.4);
}

/* Tags */
.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #d1d1d1;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags.is-focused {
    border-color: #b1b1b1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    background: #f1f1f1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #aaa;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #b1b1b1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }
}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9999;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}


.wizhelp {
    display: flex;
    margin-bottom: 20px;
} 

.wizhelp div {
    margin-left: 20px;
    margin-top: 5px
}

.panelcheck {
    min-height: 70vh !important;
}

.titleinfo {
    margin: auto !important;
    font-size: 22px;
}