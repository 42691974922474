/*------------------------------------------------------------
ADMIN 2
------------------------------------------------------------*/

.page-sidebar,
.logo-box {
    background: #fff;
    border-right: 1px solid #e6e8eb;
}

.page-content,
.page-header,
.page-header .navbar-default,
.page-inner:not(.login-page),
.search-form,
.page-header .form-control,
.search-form #close-search {
    background: #f7f9fa;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li,
.page-sidebar-collapsed .page-sidebar-menu > ul > li > a {
    width: 59px;
}

.accordion-menu .sub-menu,
.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover > a {
    background: #fff;
}

.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu {
    border-right: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover > a {
    border-top: 1px solid #e6e8eb;
    border-right: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
}

.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu:before {
    display: block;
    content: ' ';
    background: #fff;
    position: absolute;
    width: 170px;
    height: 1px;
    top: -1px;
    left: 0;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover > a:before {
    display: block;
    content: ' ';
    background: #fff;
    position: absolute;
    width: 59px;
    height: 1px;
    top: -1px;
    left: 0;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover > a:after {
    display: block;
    content: ' ';
    background: #e6e8eb;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 0;
    left: 59px;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:after {
    display: block;
    content: ' ';
    background: #fff;
    position: absolute;
    width: 59px;
    height: 1px;
    bottom: 0;
    left: 0;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover {
    margin-top: -1px;
    margin-bottom: -1px;
}

#vmap {
    background-color: #f7f9fa !important;
}

@media (max-width: 991px) {
    .logo-sm .logo-box {
        background: #f7f9fa;
        border: 0;
    }
}

