.dyl-home {
    color: #596b8c;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-64 {
    margin-top: 64px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.page-content {
    position: relative;
    overflow: hidden;
}

.dyl-home h3,
.assetPreview h3,
.dyl-home p {
    margin: 0;
}

.dyl-home p {
    font-size: 12px;
}

.dyl-home h3,
.assetPreview h3 {
    margin-top: 4px;
    font-weight: bold;
    color: #596b8c;
}

.dyl-home .card h4.card-title,
.assetPreview h4.indications {
    margin-bottom: 16px;
}

.assetPreview h4.indications {
    font-size: 18px;
    color: #596b8c;
    margin-bottom: 32px;
}

.dyl-home.noScroll {
    overflow: hidden;
    height: calc(100vh - 72px);
    margin: 0;
}

.dyl-home ul.tabs-sequences {
    padding: 0;
    list-style-type: none;
    margin-top: 40px;
    display: flex;
    height: 40px;
    border: 1px solid #777f8c;
    border-radius: 4px;
    width: fit-content;
    margin-bottom: 24px;
}

.dyl-home ul.tabs-sequences li {
    padding: 12px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.dyl-home ul.tabs-sequences li.active {
    background-color: #596b8c;
    color: white;
}

.dyl-home ul.tabs-sequences li:not(:first-child) {
    border-left: 1px solid #777f8c;
}

.dyl-home .card,
.assetPreview .card {
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 24px;
}

.dyl-home .card h3,
.assetPreview .card h3 {
    margin-top: 0;
    margin-bottom: 8px;
}

.dyl-home button.btn-save {
    height: 40px;
    padding: 11px 19px 12px 16px;
    border-radius: 4px;
    background-color: #5893df;
    border: none;
    outline: none;
    color: white;
}

.dyl-home button.btn-save:disabled {
    cursor: progress;
    opacity: 0.8;
}

@keyframes homeEditBlink {
    0% {
        background-color: #5893df;
    }
    50% {
        background-color: #f9576b;
    }
    100% {
        background-color: #5893df;
    }
}

.dyl-home button.btn-save.blink,
.dyl-home .mobile-buttons .btn-save.blink {
    animation: homeEditBlink 1.5s infinite ease-in-out;
}

.dyl-home button.btn-save i {
    margin-right: 8px;
}

.dyl-home .flex .title-seq {
    flex-grow: 1;
    text-align: initial;
}

.dyl-home .flex .title-seq h3 i {
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
}

.dyl-home .sequence-details .home-editor {
    flex-grow: 1;
    margin-right: 16px;
}

.dyl-home .sequence-details .home-editor-cards {
    flex-basis: 292px;
    min-width: 292px;
    max-width: 292px;
    height: fit-content;
}

.dyl-home .card h4 {
    margin: 0;
    font-size: 18px;
    color: #596b8c;
}

.dyl-home .card h4.flex.space-between {
    justify-content: space-between;
    margin-bottom: 24px;
}

.dyl-home .device-home-switch .switchery {
    height: 20px;
    width: 38px;
}

.dyl-home .device-home-switch .switchery > small {
    height: 20px;
    width: 20px;
}

.dyl-home .home-editor-cards .screen {
    display: flex;
    flex-direction: column;
}

.dyl-home .home-editor-cards .screen .flex.center {
    align-items: center;
}

.dyl-home .home-editor-cards .screen .flex.center span {
    text-align: left;
}

.dyl-home .home-editor-cards .screen p.currently {
    color: #777f8c;
    font-size: 12px;
    margin-left: 33px;
    margin-bottom: 16px;
}

.dyl-home .home-editor-cards .checkbox {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin: 0px 16px 0px 0;
    border-radius: 1.3px;
    border: solid 1px #777f8c;
    background-color: #ffffff;
    font-family: 'Font Awesome 5 Free';
    cursor: pointer;
}

.dyl-home .home-editor-cards .checkbox.checked {
    background-color: #5893df;
    border-color: #5893df;
}

.dyl-home .home-editor-cards .checkbox.checked::after {
    content: '\f00c';
    color: white;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    font-size: 10px;
    position: absolute;
    top: 2px;
    left: 2px;
}

.dyl-home .flex-row {
    display: flex;
    margin-top: 47px;
    flex-direction: row-reverse;
    flex-grow: 1;
}

.dyl-home .flex-row > .summary {
    flex-basis: 392px;
    margin-left: 24px;
    display: block;
    max-width: 392px;
    min-width: 392px;
}

.dyl-home .flex-row .sequences {
    flex-grow: 1;
}

.dyl-home .card.seq,
.assetPreview .card.seq {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
}

.dyl-home .card.seq p,
.assetPreview .card.seq p,
.dyl-home .card.seq-editor p {
    font-size: 14px;
    color: #777f8c;
}

.dyl-home .card.seq .labels,
.assetPreview .card.seq .labels {
    position: absolute;
    top: -8px;
    left: 8px;
    right: auto;
    bottom: auto;
}

.dyl-home .card.seq .labels span,
.assetPreview .card.seq .labels span {
    font-size: 12px;
    color: #fff;
    padding: 3px 8px 1px 8px;
    background-color: #52cc52;
    margin-right: 4px;
    border-radius: 2px;
    height: 16px;
    line-height: 0;
}

.dyl-home .card.seq .labels span i {
    font-size: 10px;
}

.dyl-home .card.seq .labels span.future,
.assetPreview .card.seq .labels span.future {
    background-color: #ff9900;
}

.dyl-home .card.seq .labels span.draft,
.assetPreview .card.seq .labels span.draft {
    background-color: #663333;
}

.dyl-home .card.seq .btn-container .tooltip-menu {
    position: absolute;
    background-color: #fff;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 0.5px #777f8c;
    flex-direction: column;
    right: 0;
    top: 40px;
    opacity: 0;
    display: flex;
    transition: opacity 0.2s ease-in-out;
    visibility: hidden;
}

.dyl-home .card.seq .btn-container .tooltip-menu.active {
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.dyl-home .card.seq .btn-container .tooltip-menu span,
.dyl-home .card.seq .btn-container .tooltip-menu a {
    padding: 8px;
    cursor: pointer;
    line-height: 1;
    color: #777f8c;
    user-select: none;
}

.dyl-home .card.seq .btn-container .tooltip-menu span:hover,
.dyl-home .card.seq .btn-container .tooltip-menu a:hover {
    background-color: #eee;
}

.dyl-home .summary {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
    flex-wrap: wrap;
}

.dyl-home .summary .number-summary {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
    align-items: center;
    max-width: 50%;
}

.dyl-home .summary .number-summary .num {
    font-size: 48px;
    font-weight: bold;
    margin-right: 8px;
    flex-basis: 54px;
    text-align: center;
    color: inherit;
    display: flex;
    min-width: 54px;
    justify-content: center;
}

.dyl-home .summary .number-summary .txt {
    font-size: 18px;
    color: inherit;
    flex-grow: 1;
    display: flex;
}

.dyl-home .btn-container {
    display: flex;
    position: relative;
}

.dyl-home .btn-large i {
    margin-right: 8px;
    font-size: 12px;
    margin-bottom: 2px;
}

.dyl-home .btn-large {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #5893df;
    outline: none;
    border: none;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
    font-size: 14px;
}

.dyl-home .btn-large.btn-large-full {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.dyl-home .btn-large + .btn-large-arrow {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #5893df;
    outline: none;
    border: none;
    color: #fff;
    height: 40px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    cursor: pointer;
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.1);
    width: 26px;
    justify-content: center;
}

.dyl-home .btn-large + .btn-large-arrow i {
    font-size: 9px;
}

.dyl-home .btn-dyl-seq {
    background-color: #f8485e;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 4px;
    height: 40px;
    display: flex;
    width: 170px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    align-self: center;
    margin: 42px auto 0 auto;
}

.dyl-home .btn-dyl-seq.disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.dyl-home .btn-dyl-seq i {
    margin-right: 8px;
    font-size: 12px;
}

.dyl-home .mobile-buttons {
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: center;
    bottom: 24px;
    right: 24px;
    z-index: 9;
}

.dyl-home .mobile-buttons .btn-save {
    width: 56px;
    height: 56px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #5893df;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 28px;
    font-size: 24px;
}

.dyl-home .mobile-buttons .btn-ret {
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f8f9fa;
    font-size: 16px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #596b8c;
    margin-top: 16px;
}

.dyl-home.dyl-sequences-page header {
    margin-bottom: 40px;
}

.dyl-home.ads .content {
    margin-top: 40px;
}

.dyl-home.ads .content .banner-camp {
    border-radius: 4px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 8px;
    margin-bottom: 16px;
}

.dyl-home.ads .content .creaBlock {
    border-bottom: none;
    margin-bottom: 24px;
}

.dyl-home.ads .content .creaBlock h4 strong {
    font-size: 18px;
    margin-bottom: 16px;
    display: block;
}

.dyl-home.ads .content .creaBlock h4 p {
    color: #777f8c;
    font-size: 14px;
}

.dyl-home.ads .content .creaBlock h4 p i {
    margin-right: 8px;
}

.dyl-home.ads .content .creaBlock video {
    border-radius: 4px;
}

.assetPreview video.videoPreview {
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 20%);
    border-radius: 3px;
}

.dyl-home.ads .creaBlock .content-data {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    flex-grow: 1;
}

.dyl-home.ads .creaBlock .content-data .msg {
    padding: 0;
}

.dyl-home.ads .creaBlock .content-data .data {
    margin-top: 32px;
    display: flex;
    flex-grow: 1;
    width: 100%;
    color: #777f8c;
}

.dyl-home.ads .creaBlock .content-data .data .eans-table {
    width: 40%;
    display: flex;
}

.dyl-home.ads .creaBlock .content-data .data .eans-table strong {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
    display: block;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
}

.dyl-home.ads .creaBlock .content-data .data .eans-table p {
    line-height: 26px;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dyl-home.ads .creaBlock .content-data .data .eans-table p:nth-child(2n) {
    background-color: #f8f9fa;
}

.dyl-home.ads .creaBlock .content-data .data .eans-table .products {
    flex-grow: 1;
}

.dyl-home.ads .creaBlock .content-data .data .infos {
    margin-left: 34px;
}

.dyl-home .editor-slides .slide {
    position: relative;
}

.dyl-home .editor-slides .slide-time-edition {
    position: absolute;
    top: 0;
    background-color: #596b8c;
    width: 100%;
    height: 32px;
    display: flex;
    color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 9px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.8;
}

.dyl-home .editor-slides .slide:hover .slide-time-edition {
    opacity: 1;
}

.dyl-home .editor-slides .slide-time-edition input {
    width: 50px;
    outline: none;
    border: none;
    margin-left: 16px;
    color: black;
}

@media (max-width: 760px) {
    .dyl-home .flex-row {
        flex-direction: column;
        margin-top: 24px;
    }

    .dyl-home .flex-row > .summary {
        margin-left: 0;
        flex-basis: auto;
        max-width: none;
        min-width: auto;
    }

    .dyl-home .summary .number-summary .num {
        min-width: 34px;
        margin-right: 4px;
    }

    .dyl-home .card.seq,
    .assetPreview .card.seq {
        padding-bottom: 64px;
    }

    .dyl-home .btn-container,
    .assetPreview .btn-container {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }

    .dyl-home .card.seq .content h3,
    .assetPreview .card.seq h3 {
        color: #777f8c;
        font-weight: bold;
        font-size: 22px;
    }

    .dyl-home .sequence-details > .flex {
        flex-direction: column;
    }

    .dyl-home .sequence-details .home-editor {
        margin-right: 0;
    }

    .dyl-home .editor-slides {
        justify-content: space-between;
    }

    .dyl-home button.btn-save {
        display: none;
    }

    .dyl-home .mobile-buttons {
        display: flex;
    }

    .dyl-home .SlideBottom p {
        font-size: 10px;
    }

    .dyl-home .card.seq .labels {
        left: auto;
        right: 8px;
    }

    .dyl-home.dyl-sequences-page header,
    .dyl-home.assets header {
        margin-bottom: 8px;
    }

    .dyl-home.dyl-sequences-page .card.seq-editor {
        padding: 16px;
    }
}

.dyl-home.assets .search-filters,
.dyl-home.tutorials .search-filters {
    margin-top: 40px;
    margin-bottom: 32px;
    position: relative;
    display: flex;
}

.dyl-home.assets .search-filters input.search,
.dyl-home.tutorials .search-filters input.search {
    width: 402px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    outline: none;
    border: none;
    padding: 0 10px 0 39px;
    position: relative;
    display: block;
}

.dyl-home.assets .search-filters input.search::placeholder,
.dyl-home.tutorials .search-filters input.search::placeholder {
    opacity: 0.5;
}

.dyl-home.assets .search-filters::after,
.dyl-home.tutorials .search-filters::after {
    font-family: 'Font Awesome 5 Free';
    height: 16px;
    width: 16px;
    content: '\f002';
    font-weight: 900;
    color: #596b8c;
    top: 9px;
    position: absolute;
    display: block;
    left: 16px;
    font-size: 16px;
}

.dyl-home.assets .asset-columns {
    display: flex;
    flex-grow: 1;
}

.dyl-home.assets .side {
    flex-basis: 292px;
    margin-right: 16px;
    min-width: 292px;
    position: absolute;
    max-height: calc(100vh - 120px - 72px - 42px);
    overflow-y: scroll;
    max-width: 292px;
}

.dyl-home.assets .side::-webkit-scrollbar {
    display: none;
}

.dyl-home.assets .side h4,
.dyl-home .editor-assets .home-editor-cards h4 {
    margin-bottom: 24px;
}

.dyl-home.assets .side .card.selection h4,
.dyl-home.editor-modal .card.selection h4 {
    margin-bottom: 16px;
}

.dyl-home.assets .side .card.selection p,
.dyl-home.editor-modal .card.selection p {
    font-size: 14px;
    color: #777f8c;
}

.dyl-home.assets .assets {
    flex-grow: 1;
    margin-left: calc(292px + 16px);
}

.dyl-home .editor-assets .assets {
    flex-grow: 1;
    margin-left: 24px;
    position: relative;
}

.dyl-home.assets .assets .card,
.dyl-home .editor-assets .assets .card {
    overflow-y: scroll;
    max-height: calc(100vh - 120px - 72px - 42px);
    margin-bottom: 0;
}

.dyl-home .tutorials .card h3 {
    padding-top: 24px;
}

.dyl-home.assets .assets span.card-asset-title,
.dyl-home .editor-assets .assets span.card-asset-title {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-bottom: 16px;
    padding-top: 5px;
}

.dyl-home.assets .assets span.card-asset-title h2,
.dyl-home .editor-assets .assets span.card-asset-title h2 {
    margin: 0 8px 0 0;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #596b8c;
}

.dyl-home .editor-assets .assets span.card-asset-title h5,
.dyl-home.assets .assets span.card-asset-title h5 {
    margin: 0 8px 0 0;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #596b8c;
}

.dyl-home.assets .assets .asset-list,
.dyl-home .editor-assets .assets .asset-list {
    margin-bottom: 40px;
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
}

.dyl-home.assets .assets .asset-list .asset-thumb,
.dyl-home .editor-assets .assets .asset-list .asset-thumb {
    margin: 0 12px;
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
}

.dyl-home.assets .assets .asset-list .asset-thumb .controls,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .controls {
    position: absolute;
    top: -12px;
    right: -12px;
    margin-top: 0;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round {
    background-color: #596b8c;
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    border-radius: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round i,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round i {
    font-size: 10px;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.check,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    transition: background-color 0.2s ease-in-out;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.check.selected,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check.selected {
    background-color: #5893df;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.check i,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check i {
    color: #777f8c;
    opacity: 0.25;
    transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.check.normal i,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check.normal i {
    color: #686868;
    opacity: 1;
}

@media (max-width: 760px) {
    .dyl-home.assets .assets .asset-list .asset-thumb .round.check.normal,
    .dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check.normal {
        display: none;
    }
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.check.selected i,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.check.selected i {
    color: #fff;
    opacity: 1;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.warning,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.warning {
    color: #ffffff;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fd8200;
    position: relative;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.warning:hover .tooltip-ratio,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.warning:hover .tooltip-ratio {
    display: block;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.processing,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.processing {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    transition: background-color 0.2s ease-in-out;
}

.dyl-home.assets .assets .asset-list .asset-thumb .round.processing i,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .round.processing i {
    color: #5893df;
    opacity: 1;
    animation: spin infinite 2s linear;
}

.dyl-home.assets .assets .asset-list .asset-thumb .tooltip-ratio,
.dyl-home .editor-assets .assets .asset-list .asset-thumb .tooltip-ratio {
    position: absolute;
    background-color: #fff;
    box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 25%);
    color: #596b8c;
    padding: 10px;
    z-index: 9;
    width: 300px;
    bottom: 0;
    display: none;
    text-align: center;
    font-size: 10px;
}

.dyl-home.assets .assets .asset-list .asset-thumb img,
.dyl-home .editor-assets .assets .asset-list .asset-thumb img {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: none;
    transition: box-shadow 0.2s ease-in-out;
}

.dyl-home.assets .assets .asset-list .asset-thumb img.selected,
.dyl-home .editor-assets .assets .asset-list .asset-thumb img.selected {
    box-shadow: 0px 0px 0px 4px #5893df;
}

.dyl-home.assets .assets .asset-list .add-asset {
    height: 160px;
    width: 90px;
    border: 2px dashed #596b8c;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.dyl-home.assets .assets .asset-list .add-asset::after {
    content: '\f067';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.dyl-home.assets .assets .asset-list .add-asset:hover {
    background-color: #596b8c;
    color: white;
}

.dyl-home.assets .home-editor-cards .category,
.dyl-home .editor-assets .home-editor-cards .category {
    margin-top: 12px;
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.dyl-home.assets .home-editor-cards .category.manual,
.dyl-home .editor-assets .home-editor-cards .category.manual {
    margin-left: 0;
    font-weight: bold;
}

.dyl-home.assets .home-editor-cards .category .cat,
.dyl-home .editor-assets .home-editor-cards .category .cat {
    font-size: 12px;
    color: #777f8c;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
}

.dyl-home.assets .home-editor-cards .category .cat i,
.dyl-home .editor-assets .home-editor-cards .category .cat i {
    width: 18px;
    margin-left: 0;
    color: inherit;
    font-size: inherit;
}

.dyl-home .editor-assets .home-editor-cards .category .cat img {
    margin-left: 0;
    width: 32px;
    margin-right: 4px;
}

.dyl-home.assets .home-editor-cards .category a,
.dyl-home .editor-assets .home-editor-cards .category a,
.dyl-home.assets .home-editor-cards .category .link {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
    color: #777f8c;
    align-self: center;
    user-select: none;
    cursor: pointer;
}

.dyl-home.assets .home-editor-cards .category a.active,
.dyl-home.assets .home-editor-cards .category .link.active,
.dyl-home .editor-assets .home-editor-cards .category a.active,
.dyl-home.assets .home-editor-cards .category .cat.active,
.dyl-home.tutorials .table-of-contents a.active {
    color: #5893df;
}

.dyl-home.assets .home-editor-cards .category .cat a {
    font-weight: bold;
}

.dyl-home.assets .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dyl-home.assets .buttons .btn-dyl,
.dyl-home.editor-modal .buttons .btn-dyl {
    margin-top: 16px;
}

/* Contact */
.dyl-home.contact .contact-columns {
    display: flex;
}

.dyl-home.contact .contact-columns .messages-list {
    display: flex;
    flex-basis: 360px;
    flex-direction: column;
    min-width: 360px;
}

.dyl-home.contact .contact-columns .messages-list .button-ctr {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.dyl-home.contact .contact-columns .messages-list .button-ctr .btn-new-message {
    padding: 11px 18px 10px 18px;
    border-radius: 4px;
    background-color: #5893df;
    color: white;
    cursor: pointer;
}

.dyl-home.contact .contact-columns .messages-list ul {
    list-style-type: none;
    padding: 0;
    overflow-y: scroll;
    max-height: calc(100vh - 240px);
}

.dyl-home.contact .contact-columns .messages-list ul li {
    padding: 16px;
    cursor: pointer;
    border-left: 4px solid transparent;
    padding-left: 12px;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0);
    margin-bottom: 16px;
    border-radius: 4px;
    position: relative;
    user-select: none;
}

.dyl-home.contact .contact-columns .messages-list ul li .unread-round {
    min-height: 8px;
    min-width: 8px;
    border-radius: 4px;
    background-color: #5893df;
    position: absolute;
    display: block;
    top: 45px;
    right: 16px;
    display: none;
}

.dyl-home.contact .contact-columns .messages-list ul li.unread .unread-round {
    display: block;
}

.dyl-home.contact .contact-columns .messages-list ul li.selected {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-left: 4px solid #5893df;
}

.dyl-home.contact .contact-columns .messages-list ul li.unread h3 {
    font-weight: bold;
}

.dyl-home.contact .contact-columns .messages-list ul li.selected h3 {
    color: #5893df;
    font-weight: bold;
}

.dyl-home.contact .contact-columns .messages-list ul li .info {
    display: flex;
    font-size: 12px;
    color: #777f8c;
    justify-content: space-between;
    align-items: center;
}

.dyl-home.contact .contact-columns .messages-list ul li .info .pastille .status {
    height: 8px;
    border-radius: 4px;
    width: 25px;
    margin-bottom: 3px;
}

.dyl-home.contact .contact-columns .messages-list ul li h3 {
    font-size: 18px;
    font-weight: normal;
    color: #777f8c;
    margin: 0;
    margin-top: 8px;
    transition: color 0.2s ease-in-out;
}

.dyl-home.contact .contact-columns .messages-list ul li > p {
    font-size: 14px;
    font-style: oblique;
    color: #777f8c;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dyl-home.contact .contact-columns .messages-list ul li > .delete {
    position: absolute;
    right: 8px;
    top: 38px;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    color: #777f8c;
    opacity: 0.33;
    display: none;
    transition: opacity 0.2s ease-in-out;
}

.dyl-home.contact .contact-columns .messages-list ul li > .assigned {
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #6982f9;
    display: flex;
    padding-top: 4px;
    border-radius: 8px;
}

.dyl-home.contact .contact-columns .messages-list ul li.selected > .delete {
    display: flex;
}

.dyl-home.contact .contact-columns .messages-list ul li > .delete:hover {
    opacity: 1;
}

.dyl-home.contact .message-content {
    display: flex;
    margin-left: 16px;
    flex-grow: 1;
    height: fit-content;
}

.dyl-home.contact .message-content .card {
    width: 100%;
}

.dyl-home.contact .message-content h4 {
    color: #5893df;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 32px;
}

.dyl-home.contact .message-content .message h6 {
    font-size: 12px;
    font-weight: bold;
    color: #777f8c;
    margin: 0;
    margin-bottom: 8px;
}

.dyl-home.contact .message-content .message:not(:last-child) {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.dyl-home.contact .message-content .message p {
    font-size: 14px;
    color: #777f8c;
    line-height: 1;
}

.dyl-home.contact .message-content .contact-infos {
    margin-top: 16px;
}

.dyl-home.contact .message-content .contact-infos p {
    font-size: 14px;
    font-style: oblique;
    color: #777f8c;
    margin-top: 8px;
    line-height: 1;
}

.dyl-home.contact .message-content .message-add {
    margin-top: 32px;
}

.dyl-home.contact .message:nth-child(2n) {
    margin-left: 16px;
}

.dyl-home.contact .new-message .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dyl-home.contact .new-message .buttons span.attachement {
    cursor: pointer;
    margin-right: 27px;
    color: #5893df;
    text-decoration: underline;
    min-width: 130px;
}

.dyl-home.contact .new-message .buttons span.attachement i {
    margin-right: 8px;
}

.dyl-home.contact .new-message .buttons .file {
    display: none;
}

.dyl-home.contact .new-message .form-group {
    margin-bottom: 24px;
}

.dyl-home.contact .message-content .attachements {
    margin-top: 8px;
}

.dyl-home.contact .message-content .attachements i {
    margin-right: 8px;
}

.dyl-home.contact .message-content .attachements,
.dyl-home.contact .message-content .attachements .flex-cols {
    display: flex;
    align-items: flex-start;
    color: #777f8c;
}

.dyl-home.contact .message-content .attachements .files {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}

.dyl-home.contact .message-content .attachements .files a {
    color: #5893df;
    font-style: oblique;
    text-decoration: underline;
    cursor: pointer;
}

.dyl-home.contact .attachements-list {
    display: flex;
    flex-wrap: wrap;
    margin: -4px 0;
}

.dyl-home.contact .attachements-list .att-item {
    padding: 6px 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin: 4px 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.dyl-home.contact .attachements-list .att-item:hover {
    background-color: #d8b0b0;
}

.dyl-home.contact .attachements-list .att-item i {
    margin-right: 8px;
}

.dyl-home .mobile-controls {
    display: none;
}

.dyl-home.info .flex-row {
    flex-direction: row;
    margin-top: 40px;
}

.dyl-home.info .flex-row .mag {
    flex-basis: 343px;
    display: flex;
    flex-direction: column;
    min-width: 343px;
}

.dyl-home.info .flex-row .screens {
    display: flex;
    flex-grow: 1;
    margin-left: 16px;
    height: fit-content;
}

.dyl-home.info .card {
    margin-bottom: 16px;
}

.dyl-home.info .flex-row .screens .card {
    width: 100%;
}

.dyl-home.info .mag-info-flex {
    display: flex;
    color: #777f8c;
    margin-top: 16px;
}

.dyl-home.info .mag-info-flex.m24 {
    margin-top: 24px;
}

.dyl-home.info .mag-info-flex > span:first-child {
    width: 100px;
}

.dyl-home .btn-dyl {
    background-color: #5893df;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 11px 19px 9px 16px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease-in-out;
}

.dyl-home .btn-dyl:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.dyl-home .btn-dyl.danger {
    background-color: #f8485e;
}

.dyl-home .btn-dyl:hover {
    background-color: #74a5e6;
}

.dyl-home .btn-dyl.danger:hover {
    background-color: #f77080;
}

.dyl-home .btn-dyl:disabled:hover {
    background-color: #5893df;
}

.dyl-home .btn-dyl.danger:disabled:hover {
    background-color: #f8485e;
}

.dyl-home .btn-dyl i {
    margin-right: 8px;
}

.dyl-home.info .flex-row .screens .flex-screens {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dyl-home.info .flex-row .screens .flex-screens select {
    width: 295px;
    margin-right: 32px;
}

.dyl-home.info .flex-row .screens .screen-data {
    margin-top: 34px;
    display: flex;
    justify-content: space-between;
    max-width: 600px;
}

.dyl-home.info .flex-row .screens .mag-info-flex > span:first-child {
    width: 110px;
}

.dyl-home.info .flex-row .screens .mag-info-flex span i {
    margin-right: 8px;
}

.dyl-home.info .flex-row .screens .on-off-time {
    margin-top: 8px;
    margin-left: 16px;
    color: #777f8c;
    font-weight: 300;
    font-size: 14px;
    display: flex;
}

.dyl-home.info .flex-row .screens .on-off-time span {
    width: 54px;
}

.dyl-home.info .mobile-controls {
    display: none;
}

.sidebar-mobile {
    display: none;
}

.sidebar-mobile-side {
    display: none;
}

.dyl-home header .logo {
    display: block;
    position: relative;
    height: 32px;
    display: none;
    margin-top: 28px;
    margin-bottom: 40px;
}

.dyl-home header .logo img {
    height: 100%;
}

.dyl-home .notification-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 16px;
    flex-direction: row;
}

.dyl-home .notification-container .bar {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(119, 119, 119, 0.5);
    background-color: #ffffff;
    padding: 7px 8px 4px 8px;
    user-select: none;
}

.dyl-home .notification-container .bar i {
    margin-right: 8px;
}

.dyl-home .notification-container .bar > div,
.dyl-home .notification-container .bar > div a {
    padding: 0 8px;
    color: #596b8c;
    opacity: 0.33;
}

.dyl-home .notification-container .bar > div a {
    opacity: 1;
}

.dyl-home .notification-container .bar > div.active,
.dyl-home .notification-container .bar > div.active a {
    color: #52cc52;
    opacity: 1;
}

.dyl-home .notification-container .bar > div.active.danger {
    color: #f8485e;
}

.dyl-home .notification-container .notif-button {
    height: 32px;
    width: 32px;
    background-color: #596b8c;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
    margin-left: 16px;
    cursor: pointer;
}

.dyl-home .notification-container .notification-card {
    position: absolute;
    right: 0;
    background-color: #fff;
    top: 40px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #c1c7cd;
    user-select: none;
}

.dyl-home .notification-container .notification-card span.none {
    font-style: italic;
    color: #c1c7cd;
}

.dyl-home.tutorials .flex-row {
    flex-direction: row;
    margin-top: 0;
}

.dyl-home.tutorials .table-of-contents {
    display: flex;
    width: 292px;
    flex-basis: 292px;
}

.dyl-home.tutorials .table-of-contents .card {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.dyl-home.tutorials .tutorials {
    margin-left: 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.dyl-home.tutorials .tutorials .banner-tutos {
    border-radius: 4px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 8px;
    margin-bottom: 16px;
    background-color: #f8485e;
    color: #fff;
    padding-left: 16px;
}

.dyl-home.tutorials .tutorials iframe {
    margin-top: 0;
    max-width: 600px;
    max-height: 339px;
}

.dyl-home.tutorials .table-of-contents h4 {
    margin-bottom: 8px;
}

.dyl-home.tutorials .table-of-contents a {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 300;
    color: #777f8c;
    cursor: pointer;
}

.asset-preview-modal .assetPreview {
    display: flex;
    flex-direction: row;
}

.asset-preview-modal .assetPreview .asset {
    max-width: 315px;
    margin-right: 24px;
}

.asset-preview-modal .assetPreview .asset img {
    max-width: 100%;
}

.asset-preview-modal .assetPreview .asset p {
    margin-top: 8px;
    color: #777f8c;
    font-size: 14px;
}

.asset-preview-modal .assetPreview .asset p i {
    margin-right: 8px;
}

.asset-preview-modal .modal-dialog {
    min-width: 60vw;
}

.asset-preview-modal .btn-dyl {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #5893df;
    outline: none;
    border: none;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
    font-size: 14px;
}

.asset-preview-modal .btn-dyl:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.asset-preview-modal .btn-dyl i {
    margin-right: 8px;
    font-size: 12px;
}

.asset-preview-modal .sequences {
    width: 100%;
}

.dyl-home .stacks {
    display: flex;
    margin-left: 24px;
}

.dyl-home .stacks > div {
    padding: 13px 16px 11px 16px;
    border: solid 1px #777f8c;
    line-height: 1;
    cursor: pointer;
}

.dyl-home .stacks > div.active {
    background-color: #596b8c;
    color: #fff;
}

.dyl-home .stacks > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
}

.dyl-home .stacks > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.editor-modal.background,
.planning-modal.background {
    position: fixed;
    top: 0;
    left: 240px;
    background-color: rgba(89, 107, 140, 0.9);
    height: 100vh;
    width: calc(100vw - 240px);
    z-index: 3;
    padding: 0 55px 0 30px;
}

.dyl-home.editor-modal .flex-row {
    flex-direction: column;
    margin-top: 24px;
}

.dyl-home.editor-modal .stacks {
    margin-left: 0;
    margin-top: 16px;
}

.dyl-home.editor-modal .stacks .stack-editor {
    border-right: none;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    padding: 11px 19px 12px 16px;
    margin-right: 16px;
}

.dyl-home.editor-modal .stacks .stack-editor.disabled {
    opacity: 0.5;
}

.dyl-home.editor-modal .stacks .stack-editor i {
    margin-right: 8px;
    font-size: 12px;
    color: inherit;
    margin-left: 0;
}

.dyl-home.editor-modal .stacks .stack-editor.selected {
    background-color: #5893df;
    color: #fff;
}

.dyl-home.editor-modal .editor-assets {
    display: flex;
}

.dyl-home.editor-modal.hidden,
.dyl-home.planning-modal.hidden {
    visibility: hidden;
}

.dyl-home.editor-modal.visible,
.dyl-home.planning-modal.visible {
    visibility: visible;
    animation: fadeIn 0.2s ease-in-out;
}

.dyl-home.editor-modal .close-btn-editor,
.dyl-home.planning-modal .close-btn-editor {
    position: absolute;
    right: 68px;
    color: #777f8c;
    font-size: 16px;
    cursor: pointer;
    top: 34px;
}

.dyl-home.planning-modal .card {
    margin-top: 24px;
}

.dyl-home.editor-modal .visible {
    visibility: visible;
}

.dyl-home.editor-modal .hidden {
    visibility: hidden;
}

.dyl-home.editor-modal .editor-window {
    margin-left: 24px;
    flex-grow: 1;
    position: relative;
}

.dyl-home.editor-modal .editor-window .card {
    overflow-y: scroll;
    max-height: calc(100vh - 120px - 72px - 42px);
    outline: none;
}

.dyl-home.editor-modal .editor-assets .cat.rayon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dyl-home.editor-modal .editor-assets .cat.rayon.active {
    color: #5893df;
}

.dyl-home.editor-modal .editor-window header {
    margin: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    width: calc(100% - 48px);
    background-color: #fff;
    z-index: 9;
    border-radius: 4px;
}

.dyl-home.editor-modal .editor-window header .prev {
    outline: none;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #777f8c;
    font-size: 14px;
    top: 8px;
    margin-right: 8px;
}

.dyl-home.editor-modal .editor-window header .buttons {
    position: absolute;
    right: 0;
    display: flex;
}

.dyl-home.editor-modal .editor-window header .button-assistant {
    display: flex;
    padding: 12px 16px;
    background-color: #5893df;
    color: #fff;
    border-radius: 4px;
    line-height: 1;
    cursor: pointer;
    border: none;
    outline: none;
}

.dyl-home.editor-modal .editor-window header .button-assistant:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.dyl-home.editor-modal .editor-window header + div {
    padding-top: 92px;
}

.dyl-home.editor-modal .editor-window header .progress-assistant {
    display: flex;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item {
    position: relative;
    width: 48px;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item .round {
    width: 24px;
    height: 24px;
    margin: 0 4px;
    padding: 3px 8px 4px;
    opacity: 0.25;
    background-color: #5893df;
    color: #fff;
    border-radius: 12px;
    user-select: none;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item.active .round {
    opacity: 1;
    cursor: pointer;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item span {
    display: none;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item.active span {
    color: #5893df;
    margin-top: 8px;
    text-align: center;
    display: block;
    font-size: 14px;
    width: 100px;
    margin-left: -33px;
    line-height: 1;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item + .item::before {
    height: 2px;
    width: 16px;
    content: '';
    background-color: #5893df;
    opacity: 0.25;
    position: absolute;
    left: -16px;
    top: 11px;
}

.dyl-home.editor-modal .editor-window header .progress-assistant .item + .item.active::before {
    opacity: 1;
}

.dyl-home.assets .asset-columns .side .btn-close,
.dyl-home.tutorials .btn-close,
.dyl-home .editor-modal .btn-close {
    display: none;
}

.editor-window .form-group.flegCheck {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    height: 38px;
}

.editor-window .form-group.flegCheck label {
    margin: 0;
    margin-right: 8px;
}

.editor-window .form-group.flegCheck input {
    margin: 0;
}

.dyl-home.dyl-sequences-page p.indications,
.dyl-home.assets p.indications,
.assetPreview p.indications,
.custom-modal.Assistant.modal-dialog p.indications {
    margin-top: 0;
    margin-bottom: 16px;
    line-height: 1;
    color: #777f8c;
    font-size: 12px;
}

.custom-modal.Assistant.modal-dialog .flex.center {
    align-items: center;
    margin-bottom: 16px;
}

.editor-assets .editor-window .groupBox label.promo-cal {
    margin-bottom: 0;
}

.editor-assets .form-control.weight {
    max-width: 100px;
}

.dyl-home .notification-container .bar > .offline-screens {
    cursor: pointer;
}

.dyl-home .notification-container .offline-screens-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(89, 107, 140, 0.9);
    z-index: 100;
    height: 100vh;
    width: 100vw;
}

.dyl-home .notification-container .offline-screens-modal .modal-screens {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    max-width: 60%;
}

.dyl-home .notification-container .offline-screens-modal .modal-screens h4.title {
    margin: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.dyl-home .notification-container .offline-screens-modal .modal-screens h4.title .close-header {
    cursor: pointer;
}

.dyl-home .notification-container .offline-screens-modal .modal-screens p.description {
    color: #777f8c;
    margin-bottom: 16px;
}

@media (max-width: 1024px) {
    .editor-modal.background,
    .planning-modal.background {
        left: 35px;
        width: calc(100vw - 35px);
    }
}

@media (max-width: 760px) {
    .dyl-home.contact .message-content {
        display: none;
    }

    .dyl-home .notification-container .offline-screens-modal .modal-screens {
        min-width: 90%;
    }

    .dyl-home.contact .message-content.active {
        display: flex;
        margin-left: 0;
        margin-bottom: 64px;
    }

    .dyl-home.contact .mobile-controls,
    .dyl-home.home .mobile-controls,
    .dyl-home.assets .mobile-controls,
    .dyl-home.tutorials .mobile-controls,
    .dyl-home .editor-modal .mobile-controls {
        display: block;
        position: fixed;
        right: 32px;
        bottom: calc(24px + 56px);
        z-index: 2;
    }

    .dyl-home .editor-modal .mobile-controls {
        bottom: 30px;
    }

    .dyl-home header {
        margin-bottom: 8px;
    }

    .dyl-home.contact .mobile-controls .btn-ret {
        height: 40px;
        width: 40px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #f8f9fa;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .dyl-home.contact .contact-columns .messages-list {
        min-width: 100%;
        flex-basis: 100%;
    }

    .dyl-home.contact .contact-columns .messages-list.active {
        display: none;
    }

    .dyl-home.contact .contact-columns .messages-list ul {
        overflow: initial;
        max-height: none;
    }

    .dyl-home.contact .contact-columns .messages-list ul li {
        box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
    }

    .dyl-home.contact .contact-columns .messages-list ul li.selected {
        border-left: 4px solid transparent;
    }

    .dyl-home.contact .contact-columns .messages-list ul li.selected h3 {
        color: #777f8c;
        font-weight: normal;
    }

    .dyl-home.assets .search-filters {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .dyl-home.assets .asset-columns .side {
        display: none;
    }

    .dyl-home.assets .asset-columns .side.visible-mob {
        display: block;
        height: 100vh;
        width: 100vw;
        min-width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(89, 107, 140, 0.9);
        z-index: 3;
        max-height: none;
        padding: 16px;
    }

    .dyl-home.assets .asset-columns .side.visible-mob .selection {
        display: none;
    }

    .dyl-home.assets .assets .asset-list .asset-thumb .round.check {
        display: none;
    }

    .dyl-home.info {
        margin-bottom: 84px;
    }

    .dyl-home.home {
        margin-bottom: 84px;
    }

    .dyl-home.info .flex-row {
        margin-top: 24px;
        flex-direction: column;
    }

    .dyl-home.info .flex-row .mag {
        flex-basis: 100%;
        min-width: 0;
    }

    .dyl-home.info .flex-row .screens {
        margin-left: 0;
    }

    .dyl-home.info .flex-row .screens .flex-screens select {
        width: 100%;
        margin: 0;
    }

    .dyl-home.info .flex-row .screens .screen-data {
        margin-top: 32px;
        flex-direction: column;
        max-width: 100%;
    }

    .dyl-home.info .mobile-controls,
    .dyl-home.contact .mobile-controls,
    .dyl-home.home .mobile-controls {
        display: block;
        position: fixed;
        right: 32px;
        bottom: calc(24px + 56px);
        z-index: 2;
    }

    .dyl-home.info .mobile-controls .btn-add,
    .dyl-home.home .mobile-controls .btn-add,
    .dyl-home.contact .mobile-controls .btn-add,
    .dyl-home.assets .mobile-controls .btn-add,
    .dyl-home.tutorials .mobile-controls .btn-add,
    .dyl-home .editor-modal .mobile-controls .btn-add,
    .dyl-home .editor-modal .mobile-controls .button-add-assets {
        height: 56px;
        width: 56px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #5893df;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #fff;
    }

    .dyl-home.home .mobile-controls .btn-add,
    .dyl-home .editor-modal .mobile-controls .button-add-assets {
        background-color: #f8485e;
    }

    .dyl-home .editor-modal .mobile-controls .button-add-assets {
        margin-bottom: 16px;
    }

    .page-sidebar.page-sidebar-closed.commerce {
        display: none;
    }

    .page-sidebar.page-sidebar-closed.commerce + .page-content .page-inner {
        padding: 6px 32px 62px 32px !important;
    }

    .sidebar-mobile {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 56px;
        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        z-index: 4;
        justify-content: space-evenly;
        align-items: center;
        padding-bottom: 8px;
    }

    .sidebar-mobile-ctr {
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        visibility: hidden;
    }

    .sidebar-mobile-ctr.expanded {
        display: flex;
        visibility: visible;
    }

    .sidebar-mobile-side {
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 244px;
        height: 100%;
        box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        z-index: 3;
        justify-content: flex-end;
        transform: translate3d(244px, 0, 0);
        transition: transform 0.2s ease-in-out;
        flex-direction: column;
        padding-bottom: calc(56px + 16px);
    }

    .sidebar-mobile-side a {
        height: 40px;
        color: #596b8c;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .sidebar-mobile-side .divider {
        display: block;
        width: 1px;
        height: 40px;
        margin: 0;
    }

    .sidebar-mobile-side a:visited {
        color: #596b8c;
    }

    .sidebar-mobile-side a i {
        margin-left: 32px;
        margin-right: 16px;
    }

    .sidebar-mobile-side a.selected {
        background-color: #f8485e;
        color: #fff;
    }

    .sidebar-mobile-side.expanded {
        transform: translate3d(0, 0, 0);
    }

    .sidebar-mobile .menu i {
        font-size: 24px;
        color: #596b8c;
    }

    .sidebar-mobile .menu.selected i {
        color: #f8485e;
    }

    .sidebar-mobile .menu {
        display: flex;
        align-items: center;
        height: 45px;
        width: 45px;
        justify-content: center;
    }

    .dyl-home header .logo {
        display: block;
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .dyl-home.contact .contact-columns .messages-list .button-ctr {
        margin: 0px 0 16px 0;
    }

    .dyl-home.ads .content {
        margin-top: 32px;
    }

    .dyl-home.ads .content .banner-camp {
        font-size: 18px;
    }

    .dyl-home.ads .content .creaBlock h4 strong {
        margin-bottom: 16px;
        font-size: 16px;
    }

    .dyl-home.ads .content .creaBlock h4 {
        margin-bottom: 24px;
    }

    .dyl-home.ads .creaBlock .creaVid {
        width: 50%;
        max-width: none;
    }

    .dyl-home.ads .creaBlock .content-data {
        padding: 0;
    }

    .dyl-home.ads .creaBlock .content-data .data {
        flex-direction: column;
    }

    .dyl-home.ads .creaBlock .content-data .data .eans-table {
        width: auto;
    }

    .dyl-home.ads .creaBlock .content-data .data .infos {
        margin-top: 24px;
        margin-left: 0;
    }

    .dyl-home.ads .creaBlock .content-data .data .eans-table p {
        font-size: 9px;
    }

    .dyl-home.home .new-sequence.false {
        display: none;
    }

    .dyl-home.home .new-sequence {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 3;
        align-items: center;
        justify-content: center;
    }

    .dyl-home.assets .assets {
        margin-left: 0;
    }

    .dyl-home.tutorials .table-of-contents {
        display: none;
    }

    .dyl-home.tutorials .tutorials {
        margin-left: 0;
        padding-bottom: 0px;
    }

    .dyl-home.tutorials .tutorials iframe {
        max-width: 100%;
        max-height: 145px;
    }

    .dyl-home.contact .message:nth-child(2n) {
        margin-left: 0;
    }

    .dyl-home.assets .assets .card {
        max-height: calc(100vh - 120px - 58px - 68px);
    }

    .dyl-home .editor-assets .assets .card,
    .dyl-home.editor-modal .editor-window .card {
        max-height: calc(100vh - 112px - 72px);
    }

    .editor-modal.background,
    .planning-modal.background {
        left: 0;
        width: 100vw;
        padding: 0 16px 0 16px;
        z-index: 9;
    }

    .dyl-home.editor-modal .close-btn-editor,
    .dyl-home.planning-modal .close-btn-editor {
        right: 8px;
        top: 8px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dyl-home.editor-modal .stacks {
        flex-wrap: wrap;
        margin-top: 0;
        justify-content: space-around;
    }

    .dyl-home.editor-modal .stacks .stack-editor {
        margin-top: 8px;
        font-size: 9px;
        padding: 11px 12px 12px 12px;
        margin-right: 0px;
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .dyl-home.editor-modal .stacks .stack-editor i {
        margin: 0;
        margin-bottom: 6px;
    }

    .dyl-home.editor-modal.background,
    .dyl-home.planning-modal.background {
        padding: 0 8px;
    }

    .dyl-home.editor-modal.background .flex-row,
    .dyl-home.planning-modal .card {
        margin-top: 8px;
    }

    .dyl-home.editor-modal.background .card,
    .dyl-home.planning-modal .card {
        padding: 16px;
        margin-bottom: 8px;
    }

    .dyl-home.editor-modal .editor-assets {
        flex-direction: column;
    }

    .dyl-home .sequence-details .home-editor-cards {
        width: 100%;
        max-width: none;
        height: auto;
        flex-basis: auto;
    }

    .dyl-home .sequence-details .editor-window,
    .dyl-home .editor-assets .assets {
        margin-left: 0;
    }

    .dyl-home.editor-modal .editor-window header {
        width: calc(100%);
        margin: -16px;
        padding: 16px;
        padding-right: 32px;
    }

    .dyl-home.editor-modal .editor-window header .buttons {
        flex-direction: column-reverse;
        right: 16px;
    }

    .dyl-home.editor-modal .editor-window header .prev {
        margin-right: 0;
    }

    .editor-window select.formpoids.form-control {
        width: auto !important;
    }

    .dyl-home .editor-assets .assets span.card-asset-title > span,
    .dyl-home.assets .assets span.card-asset-title > span {
        display: none;
    }

    .dyl-home.assets .assets span.card-asset-title h2,
    .dyl-home .editor-assets .assets span.card-asset-title h2 {
        font-size: 18px;
    }

    .asset-preview-modal .assetPreview {
        flex-direction: column;
    }

    .asset-preview-modal .assetPreview .asset {
        max-width: 50%;
        margin: 0 auto;
        display: block;
        margin-bottom: 16px;
    }

    .editor-slides .SlideContainer {
        margin-bottom: 24px;
    }

    .asset-preview-modal .assetPreview .asset p {
        text-align: center;
    }

    .dyl-home.assets .asset-columns .side.visible-mob .btn-close,
    .dyl-home.tutorials .btn-close,
    .dyl-home .editor-modal .btn-close {
        display: block;
        width: 40px;
        height: 40px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #f8f9fa;
        border-radius: 20px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .dyl-home.tutorials .search-filters input.search {
        display: none;
    }

    .dyl-home.tutorials .search-filters::after {
        display: none;
    }

    .dyl-home.tutorials .stacks {
        margin-left: 0;
    }

    .dyl-home.tutorials .table-of-contents.active {
        display: block;
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        padding: 16px;
        background-color: rgba(89, 107, 140, 0.9);
        z-index: 3;
    }

    .dyl-home.contact .message-content .card {
        padding: 16px 8px;
    }

    .dyl-home .sequence-details .home-editor-cards .category.manual {
        padding: 16px 0;
        margin: 0;
    }

    .dyl-home .editor-modal .asset-side {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 4;
        background-color: rgba(89, 107, 140, 0.9);
        padding: 8px;
    }

    .dyl-home.editor-modal .editor-window header .prev {
        display: none;
    }

    .dyl-home .btn-large,
    .assetPreview .btn-large,
    .dyl-home .btn-large + .btn-large-arrow,
    .assetPreview .btn-large + .btn-large-arrow,
    .asset-preview-modal .btn-dyl {
        height: 32px;
    }

    .page-sidebar.page-sidebar-closed.commerce + .page-content .page-inner {
        padding: 6px 24px 62px 24px !important;
    }

    .dyl-home.dyl-sequences-page {
        padding-bottom: 110px;
    }

    .dyl-home.dyl-sequences-page .mobile-buttons {
        bottom: 68px;
    }

    .dyl-home .summary > .card {
        padding: 16px;
        margin-bottom: 38px;
    }

    .dyl-home .summary .number-summary .txt {
        font-size: 14px;
    }

    .dyl-home.tutorials .search-filters {
        margin-top: 10px;
        margin-bottom: 16px;
    }

    .dyl-home.editor-modal .editor-window header.fourth {
        width: calc(100% - 10px);
        margin: -16px;
        padding: 10px;
        justify-content: flex-start;
    }

    .dyl-home.editor-modal .editor-window header.fourth .progress-assistant .item.active span {
        text-align: left;
        margin-left: 0;
    }

    .editor-window .form-group.flegCheck {
        margin-top: 8px;
        height: 10px;
        margin-bottom: 12px;
    }

    .editor-window .input-group-addon {
        padding: 6px 8px;
    }

    .editor-assets .form-control.supportSelect {
        padding-left: 2px;
        min-width: 110px;
    }

    .custom-modal.Assistant.modal-dialog video.previewAsset {
        max-width: 70%;
        max-height: none;
        margin-bottom: 16px;
    }

    .editor-modal .modal-selection {
        display: none;
    }

    .dyl-home.planning-modal .card {
        max-height: calc(100vh - 16px);
        overflow-y: scroll;
    }
}

@media (max-width: 320px) {
    .page-sidebar.page-sidebar-closed.commerce + .page-content .page-inner {
        padding: 6px 16px 62px 16px !important;
    }

    .dyl-home .summary .number-summary .txt {
        font-size: 14px;
    }

    .dyl-home .summary .number-summary .num {
        font-size: 36px;
    }

    .dyl-home.editor-modal .editor-window header {
        width: calc(100% - 10px);
        margin: -16px;
        padding: 10px;
        justify-content: flex-start;
    }

    .dyl-home.editor-modal .editor-window header .progress-assistant .item.active span {
        text-align: left;
        margin-left: 0;
    }

    .dyl-home .notification-container .bar > div {
        padding: 0 4px;
    }

    .dyl-home.editor-modal .editor-window header .buttons {
        right: 0;
    }
}

.dyl-home.dyl-sequences-page .home-editor-cards .card {
    margin-bottom: 16px;
}

.dyl-home.dyl-sequences-page .home-editor-cards .card p.info {
    color: #777f8c;
    text-align: justify;
    font-size: 12px;
}

.dyl-home.dyl-sequences-page .home-editor-cards .card.planning .btn {
    display: block;
    margin: 0 auto;
}

@keyframes loadingAnim {
    0% {
        background-color: #acacac;
    }

    100% {
        background-color: #dddddd;
    }
}

.dyl-logout-loading {
    height: 100%;
    width: 100%;
    background-color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: loadingAnim 1s ease-in-out;
}

@keyframes loadingRotateLogout {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.dyl-logout-loading svg {
    animation: loadingRotateLogout 1s linear infinite;
}

@keyframes fadeInDyl {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeInDyl {
    animation: fadeInDyl 0.2s ease-in-out;
}

.dyl-home.planning-modal p.explanation {
    color: #777;
    font-size: 11px;
    margin-top: 8px;
}

.dyl-home.planning-modal .device-planning-recap {
    display: flex;
    align-items: center;
}

.dyl-home.planning-modal .device-planning-recap .name {
    min-width: 150px;
    align-self: flex-start;
}

.dyl-home.planning-modal .device-planning-recap .progress-bar-planning {
    position: relative;
    display: block;
    height: 10px;
    width: 100%;
    border: 1px solid #adadad;
    border-radius: 3px;
    overflow: hidden;
}

.dyl-home.planning-modal .device-planning-recap .progress-bar-planning .bar-full {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #c6eaff;
    z-index: 1;
}

.dyl-home.planning-modal .device-planning-recap .progress-bar-planning .bar-planned {
    height: 100%;
    position: absolute;
    z-index: 2;
}

.dyl-home.planning-modal .device-planning-recap .days-range {
    display: flex;
    flex-grow: 1;
}

.dyl-home.planning-modal .device-planning-recap .days-range .day {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    flex-direction: column;
    padding-left: 2px;
    margin-left: 2px;
    border-left: 1px solid #777;
}

.dyl-home.planning-modal .device-planning-recap .days-range .day:first-child {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
}

.dyl-home.planning-modal .device-planning-recap .days-range .day .hours {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    justify-content: space-between;
    width: 100%;
    font-size: 10px;
    color: #777;
}

.dyl-home.planning-modal .days {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.dyl-home.planning-modal .days .day-block {
    display: flex;
    flex-direction: column;
    width: 375px;
    margin: 0 10px;
}

.dyl-home.planning-modal .days .day-block .timings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.dyl-home.planning-modal .days .day-block .timings .block-hr {
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: 1px solid #e5e9ec;
    border-right: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    user-select: none;
}

.dyl-home.planning-modal .days .day-block .timings .block-hr.selected {
    background-color: #63cb89;
    color: #fff;
    border-color: #63cb89;
}

.dyl-home.planning-modal .days .day-block .timings .block-hr:last-child {
    border-right: 1px solid #e5e9ec;
}

.dyl-home .card.planning h5 {
    display: flex;
    align-items: center;
}

.dyl-home .card.planning .planning-home-switch {
    margin-left: 10px;
}

@media (max-width: 425px) {
    .dyl-home.planning-modal .device-planning-recap {
        flex-direction: column;
        align-items: flex-start;
    }

    .dyl-home.planning-modal .device-planning-recap .name.time {
        display: none;
    }

    .dyl-home.planning-modal .device-planning-recap .day .rest {
        display: none;
    }

    .dyl-home.planning-modal .device-planning-recap .days-range {
        width: 100%;
    }
}

.dyl-home.contact .buttons-contact {
    margin-bottom: 24px;
    margin-top: -24px;
    display: flex;
    align-items: center;
}

.dyl-home.contact .buttons-contact button,
.dyl-home.contact .buttons-contact .element {
    margin-right: 10px;
}

.dyl-home.contact .buttons-contact select.form-control {
    width: auto;
}

.flex.space-between {
    justify-content: space-between;
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
}

.label-bs-grouped > .label:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.label-bs-grouped > .label.second {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
}

.lastSuivi {
    position: relative;
    cursor: help;
}

.lastSuivi .tooltip-suivi {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    width: 200px;
    top: -20px;
    left: -44px;
    padding: 12px;
    display: none;
    z-index: 9;
}

.lastSuivi:hover .tooltip-suivi {
    display: block;
}

.SlidesManagement .slides-creas {
    display: flex;
}

.SlidesManagement .slides-creas .crea {
    margin: 0 4px;
    border: 1px solid gray;
    height: fit-content;
}

.unsend-button {
    margin-left: 10px;
    padding: 6px 6px !important;
    line-height: 0;
    font-size: 9px;
}

.dyl-home.assets.assets-admin .card.card-assets-lib h1 {
    margin-top: 0;
    color: #596b8c;
}

.dyl-home.assets.assets-admin .assets .card {
    max-height: calc(100vh - 30px);
}

.dyl-home.assets.assets-admin .side {
    max-height: calc(100vh - 30px);
}

.dyl-home.assets-admin .side .card {
    margin-bottom: 0px;
    box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 25%);
}

.modal-assets-admin .dialog-scroll {
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid #acacac;
    border-radius: 4px;
    padding: 12px;
}

.modal-assets-admin .dialog-scroll p {
    margin: 0;
}

.modal-assets-admin .data-scroll {
    max-height: 350px;
    overflow-y: scroll;
    border: 1px solid #acacac;
}

.modal-assets-admin .data-scroll td {
    line-height: 0;
    vertical-align: middle;
    padding: 8px 8px;
}

.modal-assets-admin .data-scroll table {
    margin-bottom: 0;
}

.modal-assets-admin .data-scroll td input {
    margin: 0;
}

.modal-assets-admin .flex.calendars {
    flex-wrap: wrap;
    justify-content: space-between;
}

.dyl-home.assets-admin .home-editor-cards .category.manual.selected .cat {
    color: #5893df;
}

.dyl-home.assets-admin .home-editor-cards .category.manual {
    justify-content: space-between;
}

.dyl-home.assets-admin .home-editor-cards .category.manual .btn-flat {
    height: 15px;
    font-size: 9px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.modal-assets-admin .dropzone {
    border: 2px dashed #acacac;
    border-radius: 4px;
    min-height: 350px;
    max-height: 350px;
    overflow-y: scroll;
    padding: 10px;
}

.modal-assets-admin .file {
    margin-bottom: 10px;
    height: 60px;
    display: flex;
    border: 1px solid #c1c7cd;
    justify-content: space-between;
    padding: 8px 15px;
    position: relative;
}

.modal-assets-admin .file.add {
    justify-content: center;
    align-items: center;
    border: 1px dashed #c1c7cd;
    user-select: none;
    cursor: pointer;
}

.modal-assets-admin .file .icon {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-assets-admin .file .data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}

.modal-assets-admin .file .data p:first-child {
    font-size: 14px;
    margin-bottom: 4px;
}

.modal-assets-admin .file .data p {
    line-height: 1;
    margin: 0;
    margin-bottom: 2px;
    font-size: 8px;
}

.modal-assets-admin .file .actions {
    display: flex;
    align-items: center;
}

.modal-assets-admin .file .actions i {
    font-size: 16px;
    transition: color 0.2s ease-in-out;
    color: #596b8c;
}

.modal-assets-admin .file .actions i.fa-times {
    cursor: pointer;
}

.modal-assets-admin .file .actions i.fa-times:hover {
    color: #f8485e;
}

.modal-assets-admin .file .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #52cc52;
    margin: 0;
    transition: width 0.2s ease-in-out;
}

.modal-assets-admin .file i.rotate,
.modal-assets-admin i.rotate-sync {
    animation: spin infinite 2s linear;
}

.dyl-home.assets-admin .asset .tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 10px;
    text-align: center;
    margin-top: -8px;
    margin-bottom: 12px;
    align-items: center;
}

.dyl-home.assets-admin .asset .tags .tag {
    margin-top: 2px;
    padding: 2px 4px;
    line-height: 1;
    border-radius: 4px;
    display: inline-block;
    color: #eee;
    background-color: #777f8c;
    width: fit-content;
}

.dyl-home.assets-admin .asset .tags .tag.sign {
    background-color: #596b8c;
}

.dyl-home.assets-admin .asset .tags .tag.group {
    background-color: #47855d;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.dyl-home.assets-admin .asset .tags .tag.usage {
    background-color: rgb(58, 58, 58);
}

.dyl-home.assets-admin .asset .tags i {
    margin-right: 4px;
}

.modal-assets-admin .modal-dialog {
    min-width: 1000px;
}

.dyl-home.mbdc .sub {
    font-size: 10px;
    color: #777;
}

.modal-asset-preview .modal-dialog {
    max-height: none;
    min-width: 0;
    width: fit-content;
}

.modal-asset-preview img,
.modal-asset-preview video {
    max-width: 13vw;
}

.CampaignsEditor .brand .logos {
    display: flex;
}

.CampaignsEditor .brand .logos img {
    height: 64px;
    width: 64px;
}

.switch-class.ml-0 {
    margin-left: 0;
}

.dyl-home.formation iframe {
    width: 100%;
    height: calc(100vh - 226px);
    overflow: hidden;
    scroll-behavior: unset;
}

.history-admin-screen-management p {
    font-size: 11px;
    margin: 0;
}

.history-admin-screen-management .attachments {
    display: flex;
    margin: 0 -4px;
}

.history-admin-screen-management .attachments img {
    margin: 0 4px;
    max-width: 50px;
    max-height: 50px;
}

.dyl-home.home .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding-right: 3.75rem;
}

.dyl-home.home .alert-warning {
    color: #976f1a;
    background-color: #fef1d5;
    border-color: #feeac0;
}

.dyl-home.home .alert-info {
    color: #2c6299;
    background-color: #dbedff;
    border-color: #c9e3ff;
}

.dyl-home.home .alert .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.9375rem 1.25rem;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    font-size: 10px;
    padding: 1.05rem 1.25rem;
    background: transparent
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E")
        50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
}

.dyl-home.home .alert .btn {
    padding: 5px 16px 3px 16px;
}

.dyl-home.home .alert-warning .btn {
    border-color: #976f1a;
    color: #976f1a;
    /* f1c205 */
}

.dyl-home.home .alert-warning .btn:hover,
.dyl-home.home .alert-warning .btn:active,
.dyl-home.home .alert-warning .btn:focus {
    background-color: rgba(224, 164, 0, 0.08);
    border-color: #976f1a !important;
    color: #976f1a;
}

.dyl-home .ProductsAssistant .ProductChoose .images-matching {
    display: flex;
    flex-wrap: wrap;
}

.dyl-home .ProductsAssistant .ProductChoose .images-matching .product {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #333;
    justify-content: center;
    height: 75px;
    width: 75px;
    margin: 4px 4px;
    cursor: pointer;
}

.dyl-home .ProductsAssistant .ProductChoose .images-matching .product.selected {
    outline: 2px solid #333;
}

.dyl-home .ProductsAssistant .ProductChoose .images-matching .product img {
    max-height: 70px;
    max-width: 70px;
}

.dyl-home .home-editor-cards.dylSequence .avail-sign {
    display: flex;
    color: #333;
    align-items: center;
    padding: 8px 0px 6px 0;
    border-top: 1px solid #333;
    justify-content: space-between;
}

.dyl-home .home-editor-cards.dylSequence .avail-sign p {
    line-height: 1;
    font-size: 14px;
}

.dyl-home .home-editor-cards.dylSequence .avail-sign input {
    margin: 0;
}
