@font-face {
    font-family: 'ProximaNovaLight';
    src: url('/fonts/ProximaNova-Light.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaSB';
    src: url('/fonts/Proxima Nova Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url('/fonts/ProximaNova-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaBlack';
    src: url('/fonts/Proxima-Nova-Black.otf') format('opentype');
}

@font-face {
    font-family: 'ProximaNovaXBold';
    src: url('/fonts/ProximaNova-XBold.otf') format('opentype');
}

.Carrefour.portrait,
.Carrefour.portrait_direct {
    height: 1920px;
    width: 1080px;
}

.Carrefour span,
.Carrefour p,
.Carrefour h1,
.Carrefour h2,
.Carrefour h3,
.Carrefour h4 {
    font-family: inherit;
}

.Slide .PreviewFruitContainer .Carrefour.portrait,
.Slide .PreviewFruitContainer .Carrefour.portrait_direct {
    height: 1870px;
    width: 1030px;
}

.canvas .Carrefour.portrait,
.canvas .Carrefour.portrait_direct {
    height: calc(1920px - 50px);
    width: calc(1080px - 50px);
    position: relative;
}

.Carrefour span {
    font-family: inherit;
}

.Carrefour {
    font-family: 'ProximaNovaSB';
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.Carrefour .promo {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 325px;
}

.Carrefour .promo .promoBlock {
    font-size: 74px;
    background-color: #ff0019;
    padding: 40px 65px;
    line-height: 47px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 23px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-family: 'ProximaNovaXBold';
}

.Carrefour .promo p {
    font-size: 44px;
    line-height: 1;
    margin-bottom: 66px;
    text-transform: uppercase;
    color: #ff0019;
    font-family: 'ProximaNovaBold';
}

.Carrefour .image {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Carrefour .image img {
    width: 607px;
    height: 607px;
}

.Carrefour .data {
    background-color: #5d9732;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #fff;
    padding-right: 90px;
    padding-top: 72px;
    align-items: flex-end;
}

.Carrefour .data h1 {
    font-size: 152px;
    font-family: 'ProximaNovaBlack';
    line-height: 0.89;
    letter-spacing: -1.53px;
    color: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    text-align: right;
}

.Carrefour .data .origin {
    display: flex;
    margin-top: 39px;
    font-weight: bold;
}

.Carrefour .data .origin p {
    font-size: 65px;
    line-height: 1.18;
}

.Carrefour .data .origin img {
    max-height: 36px;
    margin-left: 24px;
}

.Carrefour .data-list {
    display: flex;
    margin: 0 -16px;
    flex-direction: column;
    align-items: flex-end;
}

.Carrefour .data-list p {
    font-size: 35px;
    margin: 0 16px;
}

.Carrefour .data-price {
    position: absolute;
    bottom: 54px;
    left: 74px;
    color: #fff;
    line-height: 1.4;
}

.Carrefour .data-price .price {
    font-family: 'ProximaNovaBlack';
    display: flex;
    justify-content: center;
}

.Carrefour .data-price .num {
    font-size: 221px;
    font-weight: 900;
    line-height: 240px;
}

.Carrefour .data-price .price .sec-part {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.Carrefour .data-price .price .sec-part .eur {
    font-size: 83px;
    font-weight: 900;
    line-height: 80px;
    text-align: left;
    font-family: 'ProximaNovaBold';
    margin-top: 38px;
}

.Carrefour .data-price .price .sec-part .cts {
    font-size: 85px;
    font-weight: 900;
    line-height: 84px;
    letter-spacing: -1.6px;
    font-weight: normal;
}

.Carrefour .data-price span.kilo-price {
    font-family: 'ProximaNovaBold';
    font-size: 34px;
    text-transform: uppercase;
}

.Carrefour .data-price .price-ctr {
    margin-top: -24px;
    font-size: 42px;
    font-family: 'ProximaNovaBold';
    text-transform: uppercase;
}

.Carrefour .labels {
    position: absolute;
    top: 24px;
    left: 24px;
    bottom: auto;
    right: auto;
    display: flex;
    flex-direction: column;
    margin: -16px 0;
    align-items: center;
}

.Carrefour .labels img {
    max-height: 155px;
    margin: 16px 0;
}

.Carrefour img.logo {
    position: absolute;
    bottom: 69px;
    right: 24px;
    max-width: none;
    margin: 0;
    width: 153px;
}

.Carrefour .data .flex-bakery {
    display: flex;
    gap: 25px;
}

.Carrefour .data .flex-bakery>.card {
    padding: 0;
    box-shadow: none;
    margin: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
}

.Carrefour .data .flex-bakery>.card p {
    font-size: 24px;
}

.Carrefour .data .flex-bakery img {
    max-width: 40px;
}
