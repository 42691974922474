.Campaign .new {
    position: absolute;
    top: 0%;
    right: 0;
    width: 50%;
    text-align: right;
}

.Campaign .new .newName {
    display: inline-block;
    width: 50%;
    margin-right: 1em;
    vertical-align: top;
}

.Campaign .new button {
    vertical-align: top;
}

.Campaign ul {
    list-style-type: none;
    padding: 0;
}

.Campaign ul li {
    line-height: 40px;
    width: 300px;
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.Campaign ul li.active {
    background-color: rgb(245, 245, 245);
}

.Campaign .buttons {
    text-align: right;
}

.Campaign .buttons .btn-info {
    margin-right: 1em;
}

.Campaign .addImage {
    text-align: right;
}

.fa-sync-alt{
    margin: 7px;
    color: blue;
} 

.fa-check{
    margin: 7px;
    color: green;
} 

.btn_all {
    display: flex;
}

.btn_all button {
    margin-left: 5px;
}

.btn_turn {
    background-color: #0070E0;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
}

.btn_checked {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
}

.fa-trash, .fa-sync-alt, .fa-check {
    color: white;
}

.imagetocheck {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: red;
    display: inline-block;
    margin-left: 10px;
    margin-right: 7px;
}

.img_check{
    display: inline-block;
}

.all_li {
    overflow: scroll;
    height: 70vh;
}